.nem-wrapper {
  padding: 0;
  position: relative;

  @include mq($mobileBreakpoint) {
    width: 100%;
    margin: 0 auto;
    padding: 0 1em;
    box-sizing: border-box;

    @include mq($removeMobileMenuBreakPoint) {
      max-width: 40em;
    }
  }
}