.case-responsibles {
    margin: 2em 0 0;

    .case-responsible {
        margin-bottom: 1em;
        max-width: 15em;
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-start;
        align-items: center;

        @include mq($mobileBreakpoint) {
            margin-left: 0.5em;
        }

        .case-responsible__img {
            display: block;
            flex: 1;
            border-radius: 50%;
            overflow: hidden;
            min-width: 5em;
            max-width: 5em;
            min-height: 5em;
            max-height: 5em;
            margin-right: 1em;

            @include mq($mobileBreakpoint) {
                min-width: 7em;
                max-width: 7em;
                min-height: 7em;
                max-height: 7em;
            }

            img {
                display: block;
                min-width: 5em;
                max-width: 5em;
                margin: 0 auto;

                @include mq($mobileBreakpoint) {
                    min-width: 7em;
                    max-width: 7em;
                }
            }
        }

        .case-responsible__content {

        }

    
        .case-responsible__text {
            margin-top: 0.25em;
            display: block;
            font-size: 0.9em;
            padding: 0 0.2em;
            box-sizing: border-box;

            @include mq($mobileBreakpoint) {
                margin-top: 0.35em;
            }
        }

        .case-responsible__sub-text {
            margin-top: 0.25em;
            display: block;
            font-size: 0.9em;
            font-weight: bold;
            color: $font-color;
            padding: 0 0.2em;
            box-sizing: border-box;

            @include mq($mobileBreakpoint) {
                margin-top: 0.35em;
            }
        }

        .case-responsible__link {
            margin-top: 0.25em;
            display: block;
            font-size: 0.85em;
            padding: 0 0.25em;
            box-sizing: border-box;
            color: $blue-color;
            text-decoration: underline;

            @include mq($mobileBreakpoint) {
                margin-top: 0.45em;
            }
        }
    }
}