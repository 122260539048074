.nem-sidebar {
  background: $brand-color;
  min-width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  z-index: 10;

  @include mq($removeMobileMenuBreakPoint) {
    background: none;
    position: relative;
    flex-flow: wrap column;
    z-index: 1;
  }

  &.nem-sidebar--with-buttons {
    background: tint($grey-light, 80%);
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    box-shadow: 0 -1px 2px rgba($black, 0.1);

    @include mq($removeMobileMenuBreakPoint) {
      flex: 1 0 auto;
      flex-flow: wrap column;
      padding: 1em;
      box-sizing: border-box;
      box-shadow: $block-shadow-large;
    }
  }

  .btn {
    flex: 1;
    max-width: 20em;
    display: block;

    &.btn--primary {
      @include btn($brand-color-light);
      margin: 1em;

      @include mq($removeMobileMenuBreakPoint) {
        margin: 0 0 1em;
        padding: 1em;
        min-height: 3.3em;
        min-width: 100%;
        font-weight: 700;
        font-size: 0.9em;
      }
    }

    &.btn--secondary {
      @include btn($grey-dark);
      margin: 1em;

      @include mq($removeMobileMenuBreakPoint) {
        margin: 0 0 1em;
        padding: 1em;
        min-height: 3.3em;
        font-weight: 700;
        font-size: 0.9em;
      }
    }

    &.btn--positive {
      @include btn($positive);
      margin: 1em auto;

      @include mq($removeMobileMenuBreakPoint) {
        margin: 0 0 1em;
        padding: 1em;
        min-height: 3.3em;
        min-width: 100%;
        font-weight: 700;
        font-size: 0.9em;
      }
    }
  }

  .nem-sidebar-item {
    display: block;
    position: relative;
    flex: 1;
    width: 5em;
    min-width: 5em;
    padding: 1em 0.5em;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);
    transition: background 250ms ease, transform 150ms ease;

    @include mq($removeMobileMenuBreakPoint) {
      background: $brand-color;
      width: 100%;
      min-width: 100%;
      min-height: 64px;
      max-height: 64px;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-flow: wrap row;
      justify-content: flex-start;
      border-radius: $r;

      &:after {
        content:'';
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        background: transparent;
        transform: translate3d(0,0,0) rotate(45deg);
        transition: background 200ms ease, transform 200ms ease;
      }
    }

    @at-root .nem-sidebar.nem-sidebar--show .nem-sidebar-item {

      &:first-child {
        order: 1;
      }

      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 3;
      }

      &:nth-child(4) {
        order: 4;
      }
    }

    &.nem-sidebar-item--more {
      @include mq($removeMobileMenuBreakPoint) {
        display: none;
      }
    }

    &:hover {
      background: $brand-color-light;
      transform: translate3d(0, -0.2em, 0);
      transition: background 150ms ease, transform 150ms ease;

      @include mq($removeMobileMenuBreakPoint) {
        transform: translate3d(-0.2em, 0, 0);

        &:after {
          background: $brand-color-light;
          transform: translate3d(-0.5em,0,0) rotate(45deg);
          transition: background 200ms ease, transform 200ms ease;
        }
      }

      .nem-sidebar-item__text {
        color: $white;
      }

      .nem-sidebar-item__icon {
        animation: scale-up-down 200ms ease both;
      }
    }

    &.nem-sidebar-item--current {
      background: $brand-color-light;

      @include mq($removeMobileMenuBreakPoint) {

        &:after {
          content:'';
          position: absolute;
          top: 0;
          right: auto;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 16px;
          height: 16px;
          background: $brand-color-light;
          transform: translate3d(-0.5em,0,0) rotate(45deg);
          transition: background 200ms ease, transform 200ms ease;
        }
      }
    }

    .nem-sidebar-item__icon {
      display: block;
      margin: 0 auto;
      width: 3em;
      height: 1.75em;

      @include mq($removeMobileMenuBreakPoint) {
        display: block;
        width: 2.5em;
        max-width: 2.5em;
        height: 2em;
        margin-right: 1em;
        flex: 1;
      }

      path, ellipse {
        fill: $white;
      }
    }

    .nem-sidebar-item__text {
      display: block;
      margin-top: 0.3em;
      font-size: 0.75em;
      font-weight: bold;
      color: $white;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($removeMobileMenuBreakPoint) {
        display: block;
        margin: 0.6em 0 0.5em;
        font-size: 0.9em;
        text-align: left;
        flex: 1;
      }
    }
  }

  //Sub menu more
  .nem-sidebar__more {
    width: 100%;
    background: shade($brand-color, 5%);
    display: none;

    @include mq($removeMobileMenuBreakPoint) {
      position: relative;
      bottom: auto;
      background: none;
      display: block;
    }

    @at-root .nem-sidebar.nem-sidebar--show .nem-sidebar__more {
      display: block;
      order: 0;
      flex: 1 0 auto;
    }

    .nem-sidebar-item {
      width: 100%;
      min-width: 100%;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-flow: wrap row;
      justify-content: flex-start;
      border-radius: $r;
      border-bottom: 1px solid rgba($white,0.1);
      cursor: pointer;

      @include mq($removeMobileMenuBreakPoint) {
        margin-top: 1em;
        background: $brand-color;
      }
    }

    .nem-sidebar-item__icon {
      display: block;
      width: 2em;
      max-width: 2em;
      height: 2em;
      margin-right: 1em;
      flex: 1;
    }

    .nem-sidebar-item__text {
      display: block;
      margin: 0.6em 0 0.5em;
      font-size: 0.9em;
      text-align: left;
      flex: 1;
    }
  }
}