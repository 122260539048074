.kyc-modal .modal-box {

    @include mq($removeMobileMenuBreakPoint) {
        width: 30em;
        height: 90%;
        max-height: 40.5em;
      }
    
    .modal-box__header .modal-box__header__right {
        width: inherit;

        .modal-box__header__btn {
            width: 4.6em;
            height: 4.6em;

            svg {
                width: 2.5em;
                height: 2.5em;
            }
        }
    }

    .order-flow-progress {
        margin: 0;

        .order-flow-progress__wrapper {
            height: 0.5em;
        }

        .order-flow-progress__bar {
            height: 0.5em;
            background: $grey-light;
        }

        .order-flow-progress__fill {
            height: 0.5em;
            background: $brand-color-light;
        }
    }
}