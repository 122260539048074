.case-tasks {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    display: block;
    background: $white;
    box-shadow: $block-shadow-large;

    @include mq($removeMobileMenuBreakPoint) {
        padding: 1.5em 7em;
    }

    @include mq($largeBreakpoint) {
        padding: 2em 7em;
    }

    .case-tasks__text {
        display: block;
        font-size: 0.9em;
        text-align: center;
        line-height: 135%;
        margin-bottom: 0.5em;

        @include mq($mobileBreakpoint) {
            font-size: 0.95em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 1em;
        }
    }

    .case-tasks__small-text {
        margin: 0;
        display: block;
        font-size: 0.75em;
        text-align: center;
        color: $font-color-light;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
            font-size: 0.8em;
        }
    }

    .task-item {
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-start;
        align-items: center;
        margin: 0.5em 0 0.2em;

        @include mq($mobileBreakpoint) {
            margin: 0.8em 0 0.2em 0.5em;
        }

        //hover styles if task-item is interactive
        &.task-item--interactive {
            cursor: pointer;

            &:hover {

                .task-item__icon {

                    path, rect, circle, polygon {
                        fill: $blue-color;
                    }
                }
                
                .task-item__text {
                    border-bottom-color: rgba($blue-color, 0.5);

                    span {
                        color: $blue-color;
                    }
                }
            }
        }

        &.task-item--success .task-item__text {

            img {
                display: none;

                &.img-success {
                    display: block;
                }
            }
        }

        .task-item__icon {
            display: block;
            width: 3em;
            height: 3em;
            margin: 0 1em 0 0;

            path, rect, circle, polygon {
                fill: $brand-color-light;
            }
        }

        .task-item__text {
            display: flex;
            flex-flow: nowrap row;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5em 0;
            box-sizing: border-box;
            border-bottom: 1px solid $border-color;
            flex: 1;

            span {
                text-align: center;
                font-size: 0.9em;
                color: $brand-color-light;

                @include mq($mobileBreakpoint) {
                    font-size: 1em;
                }
            }

            img {
                display: block;
                margin: 0.05em 0 0 auto;
                max-width: 1.5em;
                max-height: 1.5em;

                &.img-success {
                    display: none;
                }
            }
        }
    }
}