.order-tasks {

    .order-tasks__heading {
        font-size: 0.8em;
        font-weight: bold;
        position: relative;
        top: -0.1em;
       
        @include mq($mobileBreakpoint) {
            top: 0;
        }
    }

    .btn {
        position: relative;
        background: $positive;
        padding: 0.8em;
        box-sizing: border-box;
        border: none;
        font-size: 0.75em;
        margin: 0.5em 0;
        width: 14em;
        text-align: left;
        color: $white;
        border-radius: $r-btn;
        transition: background 500ms ease;

        &:after {
            content:'';
            position: absolute;
            top: 0.4em;
            right: 0.5em;
            bottom: 0;
            left: auto;
            background: url('/assets/img/icons/icon-validated.svg') no-repeat center 100%/cover;
            width: 2em;
            height: 2em;
            opacity: 0;
            transition: opacity 500ms ease;
        }

        &.btn--validated {
            background: $brand-color-light;
            transition: background 500ms ease;

            &:after {
                opacity: 1;
                transition: opacity 500ms ease;
            }
        }

        &.btn--secondary {
            background: $grey-dark;
            color: rgba($white, 0.6);
        }
    }
}