.system-notification {
    display: none;

    @at-root .main-app.main-app--system-notification .system-notification {
        width: 100%;
        height: 3.4em;
        display: block;
        position: relative;

        @include mq(700) {
            height: 2.3em;
        }
      }
    
    .system-notification__text {
        position: fixed;
        top: 0;
        width: 100%;
        background: #FFEBCA;
        font-size: 14px;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        z-index: 10;
        margin: 0;
    }
}