.content-block {
  margin: 0.2em 0 0.5em;
  padding: 0 1em;
  box-sizing: border-box;

  @include mq($removeMobileMenuBreakPoint) {
    padding: 2em 0;
  }

  .content-block__heading {
    margin: 0 0 0.2em;
    font-size: 1.2em;

    @include mq($mobileBreakpoint) {
      margin-bottom: 0.5em;
      font-size: 1.4em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-bottom: 0.3em;
      font-size: 1.7em;
    }
  }

  .content-block__text {
    margin: 0;
    font-size: 0.9em;
    line-height: 140%;
    color: $font-color-dark;

    @include mq($mobileBreakpoint) {
      line-height: 150%;
      font-size: 1.1em;
    }
  }

  &.content-block--empty {
    margin: 1em;
    padding: 1em;
    background: tint($grey-light, 50%);
    border: 1px solid $border-color;

    @include mq($removeMobileMenuBreakPoint) {
      margin-top: 2em;
    }

    .content-block__heading {
      margin: 0;
      font-size: 1.05em;
      color: $font-color-light;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($mobileBreakpoint) {
        font-size: 1.2em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1.4em;
      }
    }

    .content-block__text {
      margin: 0;
    }
  }
}