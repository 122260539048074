.dda-footer {
  background: $dda-footer;
  width: 100%;

  @include mq($removeMobileMenuBreakPoint) {
    position: fixed;
    bottom: 0;
    z-index: 2;
  }

  .nem-wrapper {
    max-width: 75em;
    margin: 0 auto;
    padding: 50px 1em;
    box-sizing: border-box;
  }

  .dda-footer__content {

    @include mq($mobileBreakpoint) {
      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
      align-items: center;
    }

    p {
      display: block;
      margin: 0;
      font-weight: 0.9em;
      color: rgba($white, 0.5);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      span {
        display: block;

        @include mq($mobileBreakpoint) {
          display: inline;
        }

        &.dda-footer-text-line {
          display: none;

          @include mq($mobileBreakpoint) {
            display: inline;
          }
        }
      }
    }

    a {
      display: block;
      margin: 1em 0 0 0;
      font-weight: 1em;
      text-decoration: underline;
      color: rgba($white, 0.5);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          margin: 0;
        }
    }
  }
}