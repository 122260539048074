.documents-table {
    overflow-x: auto;

    @include mq($mobileBreakpoint) {
        overflow-x: inherit;
    }
    
    table {
        width: 100%;
        min-width: 20em;
        border-collapse: collapse;
        border-spacing: 0;

        tbody tr:hover {
            background: rgba($blue-color, 0.05);

            td {
                color: $brand-color-light;
                cursor: pointer;
            }
        }

        thead tr {
            background: shade($white, 3%);

            td {
                font-weight: bold;
                padding-left: 1em;
                padding-right: 1em;
                box-sizing: border-box;
            }
        }

        tr:nth-child(even) {
            background: shade($white, 3%);
        }

        td {
            border-top: 1px solid shade($white, 5%);
            padding: 0.8em 1em;
            box-sizing: border-box;
            font-family: $font-standard;
            color: $font-color;
            font-size: 0.9em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include mq($mobileBreakpoint) {
                padding: 1em 0.8em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                padding: 1em;
            }

            .btn {
                @include btn($positive);
                font-weight: bold !important;
            }
        }
    }
}