.case-item {
    margin-bottom: 4em;
    min-width: 100%;
    min-width: 100%;

    @include mq($smallMobileBreakpoint) {
        min-width: 50%;
        min-width: 50%;
    }

    @include mq($mobileBreakpoint) {
        min-width: 33.333%;
        min-width: 33.333%;
    }

    .case-item__icon {
        width: 6em;
        margin: 0 auto 0.5em;
        display: block;
        background: $white;
        border-radius: 50%;

        @include mq($removeMobileMenuBreakPoint) {
            width: 8em;
        }

        @include mq($largeBreakpoint) {
            margin: 0 auto 0.8em;
            width: 9em;
        }
    }
    
    .case-item__title {
        margin: 0.5em 0 0;
        font-size: 0.9em;
        text-align: center;
        color: $brand-color-light;

        @include mq($mobileBreakpoint) {
            font-size: 1em;
        }

        @include mq($largeBreakpoint) {
            font-size: 1.1em;
        }
    }

    .case-item__price {
        display: block;
        margin: 0.2em 0;
        font-size: 0.9em;
        text-align: center;
        color: $brand-color-light;

        @include mq($largeBreakpoint) {
            margin: 0.3em 0;
            font-size: 1em;
        }
    }

    .case-item__text {
        display: block;
        margin: 0.4em 0 0;
        font-size: 0.9em;
        text-align: center;
        color: $brand-color-light;

        @include mq($largeBreakpoint) {
            margin: 0.5em 0;
            font-size: 1em;
        }
    }

    .case-item__btn {
        margin: 1em auto 0;
        padding: 0.6em 1.8em;
        display: block;
        background: $positive;
        color: $white;
        font-weight: bold;
        font-size: 0.9em;
        text-transform: uppercase;
        cursor: pointer;
        border: none;
        border-radius: $r-btn;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:hover {
            background: tint($positive, 10%);
        }

        &.case-item__btn--dark {
            background: $brand-color;

            &:hover {
                background: $brand-color-light;
            }
        }
    }
}