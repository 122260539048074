.label-checkbox-agreement {
  position: relative;

  label {
    margin: 1em 0 0.2em 0;
    font-size: 0.9em;
    font-family: $font-standard;
    color: $font-color;
    text-align: left;
    display: block;
    position: relative;
    padding: 0.4em 0.6em 0.3em 1.9em;
    pointer-events: all;

    span {
      display: block;
      position: absolute;
      top: 1px;
      left: 0;
      transition: transform 150ms ease;
    }

    .label-checkbox__circle {
      pointer-events: none;
      background: transparent;
      top: -16px;
      right: auto;
      bottom: auto;
      left: -16px;
      margin: auto;
      height: 50px;
      width: 50px;
      z-index: 1;
      border-radius: 50%;
    }

    .label-checkbox__box {
      pointer-events: none;
      border: 1px solid $border-color;
      background: white;
      height: 20px;
      width: 20px;
      z-index: 1;
    }

    .label-checkbox__check {
      position: absolute;
      pointer-events: none;
      top: 0;
      right: auto;
      bottom: auto;
      left: 7px;
      margin: auto;
      width: 7px;
      height: 16px;
      border: 2px solid $positive;
      box-sizing: border-box;
      border-top: none;
      border-left: none;
      opacity: 0;
      z-index: 2;
      transform: rotate(180deg);
      transition: transform 1s ease, opacity 300ms ease;
    }
  }

  input[type=checkbox]   {
    display:none;

    &:checked ~ label {

      .label-checkbox__circle {
        animation: grow-circle 450ms ease;
      }

      .label-checkbox__box {

      }

      .label-checkbox__check {
        opacity: 1;
        transform: scale(1) rotate(45deg);
        transition: transform 320ms ease, opacity 350ms ease;
      }
    }
  }
}