.order-flow {
  
  .order-flow__wrapper {
    @extend .wrapper;
    min-height: 20em;
    padding-bottom: 2em;

    @include mq($mobileBreakpoint) {
      min-height: 35em;
    }

    &.order-flow__wrapper--7 {
      .order-flow__content {
        .total-price {
          p {
            text-align: center;
            font-weight: bold;
            margin-top: 2.5em;
            font-size: 1.3em;
          }
        }
      }
    }
  }

  .order-flow__back {
    @extend .wrapper;
    max-width: 40em;
    z-index: 99;
    width: 100%;
    height: 1.5em;

    @include mq($removeMobileMenuBreakPoint) {
      position: relative;
    }
    

    .order-flow__back__btn {
      position: fixed;
      top: 14px;
      right: auto;
      bottom: auto;
      left: 1.5em;
      margin: auto;
      width: 22px;
      height: 22px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 99;

      @include mq($mobileBreakpoint) {
        top: 18px;
        width: 24px;
        height: 24px;
      }

      @include mq($removeMobileMenuBreakPoint) {
        position: absolute;
        top: 75px;
        left: 0;
      }

      &:hover svg path {
        fill: rgba($font-color, 0.8);
      }

      svg path {
        fill: rgba($grey-dark, 0.5);
      }
    }
  }

  .order-flow__header {
    position: relative;
    margin: 2em auto 2em;
    max-width: 40em;
  }

  .order-flow__heading {
    margin: 1.5em 0 0;
    font-size: 1.3em;
    font-weight: normal;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($mobileBreakpoint) {
      font-size: 1.85em;
    }
  }

  .order-flow__subheading {
    margin: 0.5em 0 0.2em;
    font-size: 0.8em;
    font-weight: normal;
    text-align: center;
    color: rgba($font-color, 0.8);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($mobileBreakpoint) {
      font-size: 1em;
      margin-bottom: 0.4em;
    }
  }

  .order-flow__subsubheading {
    margin-top: 0;
    font-size: 0.8em;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    color: rgba($font-color, 0.6);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($mobileBreakpoint) {
      font-size: 0.9em;
    }
  }

  .order-flow__content {
    margin: 0 auto;

    .input-fields {
      max-width: 28em;
      margin: 0 auto;
    }

    .over-flow__text-block {
      max-width: 34em;
      margin: 0 auto;

      .over-flow__text-block__heading {
        margin: 1.5em 0 0;
        font-size: 1.3em;
        font-weight: normal;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    
        @include mq($mobileBreakpoint) {
          font-size: 1.85em;
        }
      }

      p {
        margin: 1em 0 2em;
        line-height: 150%;
      }

      .label-checkbox {
        max-width: 34em;
        margin-bottom: 3em;
      }
    }

    .order-flow__content__two-col {
      display: flex;
      flex-flow: wrap row;
      max-width: 28em;
      margin: 0 auto;

      .label-input {
        width: 49%;
        flex: 1;

        &:first-child {
          margin-right: 4%;
          flex: 0.75;

          @include mq($mobileBreakpoint) {
            margin-right: 2%;
            flex: 0.5;
          }
        }
      }
    }

    .order-flow__content__expansion {
      pointer-events: none;
      margin-top: 0.5em;
      max-height: 0;
      transform: translate3d(0,-50%,0);
      opacity: 0;
      transition: transform 200ms ease, opacity 200ms ease, max-height 200ms ease;

      &.order-flow__content__expansion--auto-height.order-flow__content__expansion--open {
        max-height: inherit;
      }

      &.order-flow__content__expansion--open {
        position: relative;
        z-index: 1;
        max-height: 3em;
        pointer-events: inherit;
        transform: translate3d(0,0,0);
        opacity: 1;
        transition: transform 200ms ease, opacity 200ms ease, max-height 200ms ease;

        @at-root .order-flow.order-flow--company .order-flow__wrapper.order-flow__wrapper--2 .order-flow__content .order-flow__content__expansion.order-flow__content__expansion--open {
          max-height: 12em;
        }

        @at-root .order-flow.order-flow--company .order-flow__wrapper.order-flow__wrapper--6 .order-flow__content .order-flow__content__expansion.order-flow__content__expansion--open {
          max-height: 14em;
        }
      }
    }

    .order-flow__text-block {
      max-width: 38em;
      margin: 0 auto 2em;

      @include mq($mobileBreakpoint) {
        margin: -2em auto 2em;
      }

      p {
        font-size: 0.95em;
        text-align: center;
        line-height: 160%;
        color: rgba($font-color-dark, 0.75);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          font-size: 1.1em;
        }
      }

      a {
        color: $blue-color;
      }
    }

    .label-input {
      max-width: 28em;
      margin: 0 auto 1em;

      label {
        top: 1.1em;
        color: $grey-dark;
      }

      input, textarea {

        @include mq($mobileBreakpoint) {
          padding: 1em;
        }

        &:hover {
          background: shade($white,2%);

          &:focus {
            background: $white;
          }
        }

        &:focus ~ label, &.has-text ~ label {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          @include mq($mobileBreakpoint) {
            font-size: 0.65em;
          }
        }
      }
    }

    .label-checkbox {
      max-width: 23em;
      margin: 0 auto 2.5em;

      @at-root .order-flow__wrapper.order-flow__wrapper--4 .label-checkbox {
        max-width: 28em;
        margin: 1em auto;
      }

      label {
        cursor: pointer;

        @include mq($mobileBreakpoint) {
          padding-left: 2.2em;
          font-size: 1.1em;
        }

      }

      .circle {

        @include mq($mobileBreakpoint) {
          top: -19px;
          left: -16px;
          width: 58px;
          height: 58px;
        }
      }

      .box {

        @include mq($mobileBreakpoint) {
          background: white;
          top: -3px;
          height: 24px;
          width: 24px;
        }
      }

      .check {

        @include mq($mobileBreakpoint) {
          top: -2px;
          right: auto;
          bottom: auto;
          left: 9px;
          margin: auto;
          width: 10px;
          height: 18px;
        }
      }

      input[type=checkbox] {

        &:checked ~ label {
          text-decoration: none;
        }
      }

      .label-checkbox__subtext {
        margin: 0;
        padding-left: 2em;
        font-size: 0.85em;
        text-align: left;
        color: $grey-dark;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          padding-left: 2.9em;
        }
      }
    }

    .order-flow__expand-btn {
      display: block;
      margin: 1em auto 0;
      font-family: $font-standard;
      font-size: 0.9em;
      text-align: center;
      letter-spacing: 0.02em;
      color: $font-color-light;
      background: none;
      border: none;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: color 120ms ease;

      &:hover {
        color: $font-color;
        transition: color 120ms ease;

        &.order-flow__expand-btn--with-dropdown .drop-down-arrow path {
          fill: $font-color;
        }
      }
    }

    .order-flow__expand-btn--with-dropdown {
      display: flex;
      flex-flow: nowrap row;
      justify-content: center;

      span {
        align-self: center;
      }

      .drop-down-arrow {
        position: relative;
        width: 1em;
        height: 1em;
        margin-left: 0.4em;
        top: 0.1em;
        align-self: center;
        transform: rotate(0);
        transition: transform 150ms ease;

        path {
          fill: $font-color-light;
        }
      }
    }

    .order-flow__expand-btn--open {

      .drop-down-arrow {
        transform: rotate(180deg);
        transition: transform 150ms ease;
      }
    }


  }

  .order-flow__actions {
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    pointer-events: none;
    background: transparent;
    transition: background 200ms ease;

    &.order-flow__actions--show {
      background: rgba(shade($grey-light, 10%), 0.9);
      transition: background 200ms ease;

      @include mq($mobileBreakpoint) {
        background: transparent;
      }
    }

    &.order-flow__actions--ceo {
      padding: 0;
      position: relative;
      left: inherit;
      bottom: inherit;
      margin: inherit;
      width: inherit;
      max-width: 28em;
      margin: 1em auto 0;
      z-index: inherit;
      pointer-events: inherit;
    }

    @include mq($mobileBreakpoint) {
      padding: 0;
      position: relative;
      left: inherit;
      bottom: inherit;
      margin: inherit;
      width: inherit;
      max-width: 28em;
      margin: 4em auto 0;
      z-index: inherit;
      pointer-events: inherit;
    }

    @at-root .order-flow .order-flow__wrapper.order-flow__wrapper--10 .order-flow__actions {
      max-width: 32em;

      @include mq(365) {
        display: flex;
        flex-flow: nowrap row;
      }

      button {
        margin-bottom: 1em;

        @include mq(365) {
          margin: 0 auto;
        }
      }

      button:disabled {
        display: none;
      }
    }

    .btn {
      @include btn($positive);
      margin: 0 auto;
      display: block;
      padding: 0.8em 2em;
      border-radius: 0;
      pointer-events: none;
      box-shadow: 0 2px 4px rgba($brand-color, 0.05), 0 12px 40px rgba($brand-color, 0.05);
      transform: translate3d(0,100%,0) scale(0.8);
      opacity: 0;
      transition: transform 200ms ease, opacity 200ms ease, box-shadow 200ms ease;

      @include mq($mobileBreakpoint) {
        font-size: 1em;
        padding: 0.7em 3.2em;
      }

      &.btn--show {
        pointer-events: inherit;
        transform: translate3d(0,0,0) scale(1);
        background: $positive;
        opacity: 1;
        transition: transform 200ms ease, opacity 200ms ease;
        pointer-events: all;

        &:hover {
          transform: translate3d(0,0,0) scale(1.02);
          box-shadow: 0 2px 4px rgba($brand-color, 0.1), 0 12px 40px rgba($brand-color, 0.15);
          transition: transform 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
        }
      }
    }
  }

  .number-tiles {
    max-width: 12em;
    display: flex;
    flex-flow: wrap row;
    margin: 0 auto;

    &.number-tiles--selection .number-tiles__item {
      opacity: 0.4;

      &.number-tiles__item--selected {
        opacity: 1;
      }
    }

    .number-tiles__item {
      position: relative;
      height: 6em;
      width: 6em;
      background: $white;
      box-shadow: $block-shadow;
      cursor: pointer;
      border: 2px solid transparent;
      box-sizing: border-box;
      transform: translate3d(0,0,0) scale(1);
      opacity: 1;
      transition: transform 200ms ease, opacity 200ms ease;

      &:hover {
        z-index: 1;
        background: shade($white, 2%);
        transform: translate3d(0,0,0) scale(1.02);
        box-shadow: 0 2px 4px rgba($brand-color, 0.1), 0 12px 40px rgba($brand-color, 0.12);
        transition: transform 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
      }

      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 1em;
        height: 1em;
        text-align: center;
      }
    }
  }

  .order-tiles {
    margin: 0 auto;

    @include mq($mobileBreakpoint) {
      display: flex;
      flex-flow: wrap row;
      justify-content: center;
    }

    &.order-tiles--selection .order-tiles__item {
      opacity: 0.4;

      &.order-tiles__item--selected {
        opacity: 1;
      }
    }

    .order-tiles__item {
      position: relative;
      max-width: 18em;
      margin: 0 auto 1em;
      padding: 2em 1em;
      background: $white;
      box-shadow: $block-shadow;
      cursor: pointer;
      border: 2px solid transparent;
      box-sizing: border-box;
      transform: translate3d(0,0,0) scale(1);
      opacity: 1;
      transition: transform 200ms ease, opacity 200ms ease;

      @include mq($mobileBreakpoint) {
        flex: 1;
        min-width: 49%;
        max-width: 49%;
        margin: 0 0 0.8em 0;

        &:nth-child(odd) {
          margin-right: 1%;
        }

        &:nth-child(even) {
          margin-left: 1%;
        }
      }

      @include mq($removeMobileMenuBreakPoint) {
        min-width: 32%;
        max-width: 32%;
        margin: 0 1% 0.8em 0;

        &:nth-child(even) {
          margin-left: 0;
        }

        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }

      &:hover {
        background: shade($white, 2%);
        transform: translate3d(0,0,0) scale(1.02);
        box-shadow: 0 2px 4px rgba($brand-color, 0.1), 0 12px 40px rgba($brand-color, 0.12);
        transition: transform 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
      }

      img {
        display: block;
        width: 6em;
        height: 6em;
        margin: 0 auto 1em;

        @at-root .order-flow__wrapper.order-flow__wrapper--3 .order-tiles__item.order-tiles__item--2 img {
          width: 10.6em;
        }

        @at-root .order-flow__wrapper.order-flow__wrapper--3 .order-tiles__item.order-tiles__item--3 img {
          width: 12.8em;
        }
      }

      .order-tiles__item__count {
        display: block;
        margin-top: 1.2em;
        margin-bottom: 1em;
        font-size: 1em;
        font-weight: normal;
        text-align: center;
        color: rgba($font-color-dark, 0.75);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .order-tiles__item__heading {
        display: block;
        text-align: center;
        font-weight: bold;
      }

      .order-tiles__item__text {
        display: block;
        margin-top: 0.2em;
        text-align: center;
        color: $grey-dark;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .order-tiles__item__description {
        display: block;
        margin-top: 0.5em;
        font-size: 0.9em;
        text-align: center;
        color: $grey-dark;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .order-tiles__item__price {
        display: block;
        margin-top: 1em;
        font-size: 1em;
        font-weight: bold;
        text-align: center;
        color: $font-color;
      }

      .order-tiles__item__price-tax {
        display: block;
        margin-top: 0.2em;
        font-size: 0.8em;
        text-align: center;
        color: $grey-dark;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  .contact-time-text.contact-time-text--success {
    background: white;
    padding: 0.2em 0.3em;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    color: $brand-color;
  }
}