.main-app.main-app--st-theme {

    .main-header {
        background: $st-color;
    }

    .case-menu {
        background: $st-color;

        a {

            &:hover, &.router-link-exact-active {
                background: rgba($white, 0.1);
            }
        }

        .case-menu__more--open {
            background: $st-color;

            a {
                background: $st-color;

                &:hover {
                    background: rgba($white, 0.1);
                }
            } 
        }
    }

    .new-case {
        .new-case__heading, .new-case__subheading {
            color: $black;
        }
    }

    .order-item {

        &:hover {
            background: rgba($st-color, 0.05);
            box-shadow: inset 3px 0 0 $st-color;
        }

        &.active {
            background: rgba($st-color, 0.05);
            box-shadow: inset 3px 0 0 $st-color;

            .order-item__content {

                .order-item__caseid, .order-item__title {
                    color: $st-color;
                }
            }
        }

        .order-item__icon img {
            filter: hue-rotate(280deg) saturate(30%) brightness(1.5);
        }

        .order-item__msg-counter {
            background: $st-color;
        }
    }

    .task-item {

        svg {

            path, rect {
                fill: $st-color;
            }
        }

        .task-item__text span {
            color: $st-color;
        }
    }

    .case-insurance__item__img {
        filter: hue-rotate(280deg) saturate(30%) brightness(1.5);
    }
}