.trust-block {
  background: $brand-color;

  @include mq($mobileBreakpoint) {
    margin-top: 6em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    margin-top: 9.5em;
  }

  .trust-block__content {
    @extend .wrapper;
    display: flex;
    flex-flow: wrap column;

    @include mq($removeMobileMenuBreakPoint) {
      flex-flow: wrap row;
      padding: 3em 1em;
    }
    @include mq(1000) {
      padding: 3em 0;
    }


    .trust-block__content__text {
      flex: 1;

      h2 {
        color: $white;
        margin: 1.5em 0 0;
        font-size: 1.3em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          margin: 2em 0 0;
          text-align: center;
        }

        @include mq($removeMobileMenuBreakPoint) {
          margin: 0.4em 0 0;
          font-size: 1.6em;
          text-align: left;
        }
      }

      p {
        font-size: 0.9em;
        color: $white;
        line-height: 150%;
        margin: 0.8em 0 0.3em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          text-align: center;
        }

        @include mq($removeMobileMenuBreakPoint) {
          text-align: left;
        }
      }
    }

    .trust-block__content__image {
      position: relative;
      flex: 1;
      max-width: 16em;
      margin: 1em auto 0;

      @include mq($mobileBreakpoint) {
        max-width: 19em;
        text-align: center;
      }

      @include mq($removeMobileMenuBreakPoint) {
        text-align: left;
      }

      img {
        display: block;

        @include mq($removeMobileMenuBreakPoint) {
          position: absolute;
          top: auto;
          right: 0;
          bottom: -3em;
          left: 0;
          margin: auto;
        }
      }
    }
  }
}