.label-input {
  position: relative;

  .loading {
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      bottom:0;
      z-index: 2;
      content: '';
      width: 500%;
      border-bottom: 3px dashed rgba($brand-color,0.5);
      animation: loading-progress-infinite 10s infinite linear;

      @include mq($removeMobileMenuBreakPoint) {
        bottom:1.2em;
      }
    }
  }

  input, textarea {
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 1em;
    font-family: $font-standard;
    font-size: 1em;
    color: $font-color;
    background: $white;
    cursor: text;
    border: 1px solid transparent;
    border-radius: $r;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 0 1px rgba($black,0.1),0 1px 3px rgba($black,0.12);
    transition: background 200ms ease, color 200ms ease, border 280ms ease;

    @include mq($mobileBreakpoint) {
      padding: 1.6em 1em 0.8em;
    }

    &::-webkit-input-placeholder {
      color: $font-color;
      transition: color 200ms ease;
    }

    &::-moz-placeholder {
      color: $font-color;
      transition: color 200ms ease;
    }

    &:-ms-input-placeholder {
      color: $font-color;
      transition: color 200ms ease;
    }

    @include mq($mobileBreakpoint) {
      font-size: 1em;
    }

    &:hover {
      background: shade($white,2%);
      color: shade($font-color,40%);
      transition: background 200ms ease, color 200ms ease;

      &::-webkit-input-placeholder {
        color: $brand-color;
        transition: color 200ms ease;
      }

      &::-moz-placeholder {
        color: $brand-color;
        transition: color 200ms ease;
      }

      &:-ms-input-placeholder {
        color: $brand-color;
        transition: color 200ms ease;
      }
    }

    &:focus {
      color: $brand-color;
    }

    &:focus:hover {
      color: $brand-color;
      background: tint($brand-color,92%);
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
    }

    &:focus ~ label, &.has-text ~ label {
      left: 1.3em;
      font-size: 0.7em;
      color: rgba($font-color,0.9);
      -webkit-font-smoothing: antialiased;
      transform: translate3d(0,-0.7em,0);
      transition: transform 220ms ease, font-size 200ms ease;

      @include mq($mobileBreakpoint) {
        top: 1.2em;
        left: 1.3em;
        transform: translate3d(0,-0.5em,0);
      }
    }
  }

  label {
    position: absolute;
    top: 1em;
    left: 0.8em;
    color: $font-color;
    font-family: $font-standard;
    font-size: 1em;
    pointer-events: none;
    transition: transform 220ms ease, font-size 200ms ease;

    @include mq($mobileBreakpoint) {
      font-size: 1em;
      top: 1.3em;
      left: 1em;
    }
  }
}