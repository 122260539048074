.deposit-info {
    margin-top: 1em;
    position: relative;
    background: rgba($warning,0.1);
    padding: 1em;
    box-sizing: border-box;
    border: 1px solid rgba(shade($warning, 20%),0.2);

    @include mq($mobileBreakpoint) {
        padding: 1.5em;
    }

    .deposit-info__heading {
        font-size: 0.9em;
        text-align: left;
        margin: 0;

        @include mq($mobileBreakpoint) {
            font-size: 0.95em;
        }
    }
    
    .deposit-info__icon {
        position: absolute;
        top: 0.9em;
        right: 0.9em;
        bottom: auto;
        left: auto;
        margin: auto;
        width: 1.2em;
        height: 1.2em;

        @include mq($mobileBreakpoint) {
            top: 1.4em;
            right: 1.4em;
            top: 1.6em;
            right: 1.6em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            width: 1.6em;
            height: 1.6em;
            top: 1.3em;
            right: 1.3em;
        }
    }
}