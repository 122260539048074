.faq-block {
  margin-bottom: 1em;
  padding: 0;
  position: relative;
  display: block;

  @include mq($mobileBreakpoint) {
    margin-bottom: 1.5em;
  }

  &.faq-block--open {

    .faq-block__btn span {
      transform: rotate(0);
      transition: transform 200ms ease;

      &:first-child {
        transform: rotate(0);
        transition: transform 200ms ease;
      }
    }
  }

  .faq-block__header {
    position: relative;
    background: tint($grey-light, 80%);
    display: flex;
    flex-flow: nowrap row;
    border: 1px solid $border-color;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .faq-block__heading {
    margin: 0 0 0 1em;
    flex: 1;
    align-self: center;
    font-size: 1em;

    @include mq($mobileBreakpoint) {
      font-size: 1.1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 0 0 0 1.5em;
    }
  }

  .faq-block__btn {
    flex: 1;
    position: relative;
    min-width: 4em;
    max-width: 4em;
    min-height: 4em;
    max-height: 4em;
    margin: 0;
    padding: 0;
    border: none;
    background: $brand-color;

    @include mq($removeMobileMenuBreakPoint) {
      min-width: 5em;
      max-width: 5em;
      min-height: 5em;
      max-height: 5em;
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      width: 1.5em;
      height: 2px;
      background: $white;
      transform: rotate(-90deg);
      transition: transform 200ms ease;

      @include mq($removeMobileMenuBreakPoint) {
        width: 1.8em;
        height: 3px;
      }

      &:first-child {
        transform: rotate(-180deg);
        transition: transform 200ms ease;
      }
    }
  }

  .faq-block__content {
    background: $white;
    border: 1px solid $border-color;
    border-top: none;
    margin: 0 auto;
    width: 100%;
    padding: 1.2em 1em;
    box-sizing: border-box;

    @include mq($mobileBreakpoint) {
      padding: 1.5em 1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      padding: 1.5em;
    }

    &.faq-transition-enter-active {
      animation: faq-enter 200ms ease both;
    }

    &.faq-transition-leave-active {
      animation: faq-enter 200ms ease both reverse;
    }

    p {
      margin: 0;
      line-height: 150%;
    }
  }
}