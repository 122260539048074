.main-nav {
  display: block;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  background: rgba($brand-color,0.9);
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  transition: height 900ms ease;

  @include mq($mobileBreakpoint) {
    right: -35px;
  }

  @at-root {
    .showMenu.main-header .main-nav, .showMenu.nem-header .main-nav {
      height: 1600px;
      transition: height 900ms ease;
    }
  }

  .nav-list {
    margin-top: 80px;

    .nav-list__item {
      display: block;
      padding: 0 3em;

      @include mq($mobileBreakpoint) {
        padding: 0 5em;
      }

      a {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        text-align: right;
        color: $white;
        padding: 1em;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.2em;

        @include mq($mobileBreakpoint) {
          padding: 0.8em 1em;
          font-size: 1.3em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          padding: 0.7em 1em;
          font-size: 1.4em;
        }

        &:hover {
          color: rgba($white,0.6);
        }
      }
    }
  }
}