.wrapper-full {
  width: 100%;

  @include mq($removeMobileMenuBreakPoint) {
    max-width: 60em;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
  }

  @include chrisCoyier;
}