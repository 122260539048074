.lead-order-confirmation {
  position: relative;
  background: $white;
  padding: 2em 0;
  min-height: 24em;

  @include mq($smallMobileBreakpoint) {
    min-height: 29em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    min-height: 40em;
  }

  @include mq($largeBreakpoint) {
    min-height: 50em;
  }

  @include mq(1900) {
    min-height: 55em;
  }

  .lead-order-confirmation__bg {
    background: url("/assets/img/lead-step-2-bg.jpg") no-repeat bottom center/140%;
    min-height: 48em;
    position: absolute;
    top: auto;
    right: 0;
    bottom: -7em;
    left: 0;
    margin: auto;
    width: 100%;
    z-index: 0;

    @include mq($mobileBreakpoint) {
      background-size: 100%;
      bottom: -5em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      bottom: -3em;
    }
  }

  .lead-order-confirmation__text {
    @extend .wrapper;
    position: relative;
    z-index: 1;

    h1 {
      text-align: center;
      text-shadow: 0 0 5px $white;
      font-size: 1.3em;

      @include mq($mobileBreakpoint) {
        font-size: 1.5em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 2em;
      }
    }

    h3 {
      margin: 0.5em 0 0;
      text-align: center;
      text-shadow: 0 0 5px $white;
      font-size: 1em;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($mobileBreakpoint) {
        font-size: 1.15em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1.3em;
      }
    }

    p {
      max-width: 25em;
      margin: 2.2em auto 1em;
      font-size: 0.9em;
      text-align: center;
      color: $font-color-light;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1em;
      }
    }

    a {
      @include btn($positive);
      margin: 1em auto 1em;
      border-radius: 0;
      display: block;
      max-width: 16em;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($mobileBreakpoint) {
        font-size: 1.1em;
        font-weight: bold;
        max-width: 18em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        max-width: 21em;
      }
    }

    span {
      display: block;
      text-align: center;
      font-size: 0.8em;
      color: $font-color-light;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}