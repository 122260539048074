.tag-label {
  position: absolute;
  padding: 0.5em;
  font-family: $font-standard;
  font-size: 0.6em;
  color: $negative;
  background: rgba($negative, 0.1);
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  margin: auto;
  width: 14em;
  min-height: 1.4em;
  text-align: center;
  pointer-events: none;

  @include mq($mobileBreakpoint) {
    font-size: 0.75em;
  }
}