.profile-avatar {
  margin: 0 auto;
  position: relative;
  display: block;
  flex: 1;
  min-width: 8em;
  min-height: 8em;
  max-width: 8em;
  max-height: 8em;
  border: 2px solid $white;
  border-radius: $r-round;

  @include mq($mobileBreakpoint) {
    align-self: center;
    min-width: 12em;
    min-height: 12em;
    max-width: 12em;
    max-height: 12em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    margin-top: 1em;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
      transition: transform 250ms ease;
    }

    &.profile-avatar--disable {
      cursor: default;
      img {
        transform: scale(0);
      }
    }
  }

  .profile-avatar__img {
    @extend .avatar;
    height: 100%;
  }

  .profile-avatar__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 3em;
    height: 3em;
    z-index: 9;
    background: $blue-color;
    border-radius: $r-round;
    border: 2px solid $white;

    @include mq($mobileBreakpoint) {
      width: 4em;
      height: 4em;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1.6em;
      height: 1.6em;
      transform: scale(1);
      transition: transform 250ms ease;

      @include mq($mobileBreakpoint) {
        width: 2.4em;
        height: 2.4em;
      }
    }
  }
}