.status-bar {
    margin-top: 5em;
    display: block;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;

    @include mq($mobileBreakpoint) {
        margin-top: 5.8em;
    }

    p {
        margin: 0;
        font-size: 0.9em;
        text-align: center;

        @include mq($mobileBreakpoint) {
            font-size: 1em;
        }
    }

    //Green status
    &.status-state-1, &.status-state-6, &.status-state-7 {
        background: tint($positive, 80%);
        border-color: transparent;
    }

    //Yellow status
    &.status-state-2, &.status-state-3, &.status-state-5 {
        background: tint($yellow-color, 75%);
        border-color: transparent;
    }

    //Red status
    &.status-state-4 {
        background: tint($negative, 85%);
        color: $negative;
        border-color: transparent;
    }
}