.base-modal {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 998;
  background: rgba($black, 0.6);

  .modal-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 30em;
    background: $white;
    border: 1px solid $border-color;

    @include mq($removeMobileMenuBreakPoint) {
      max-height: 50%;
      width: 50%;
    }
  }

  .modal-box__header {
    border-bottom: 1px solid $border-color;
    display: flex;

    .modal-box__header__heading {
      padding: 1em;
      margin: 0;
      color: $font-color-dark;
      flex: 1;
      font-size: 1.2em;
    }

    .modal-box__header__btn {
      flex: 1;
      max-width: 6em;
      max-height: 100%;
      background: transparent;
      padding: 1em;
      box-sizing: border-box;
      border: none;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        background: shade($white, 10%);

        .modal-box__header__btn__icon {
          fill: $blue-color;
        }
      }

      .modal-box__header__btn__icon {
        fill: $brand-color-light;
        width: 2em;
        height: 2em;
        transition: transform 200ms ease;
      }
    }
  }

  .modal-box__content {
    padding: 1em;
  }

  .modal-box__actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $white;
    border-top: 1px solid $border-color;
    padding: 1em 0.5em;
    box-sizing: border-box;
    display: flex;

    @include mq($mobileBreakpoint) {
      padding: 1em;
    }

    .btn {
      @include btn($brand-color-light);
      display: block;
      margin: 0 auto;
      flex: 1 0 auto;
      max-width: 12.5em;

      @include mq($smallMobileBreakpoint) {
        max-width: 13em;
      }

      @include mq($mobileBreakpoint) {
        max-width: 14.5em;
        width: 14.5em;
      }

      &.btn--positive {
        @include btn($positive);
        display: block;
        margin: 0 auto;
      }
    }
  }
}