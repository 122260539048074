.nem-dropzone {
    margin: 1em 0;
    position: relative;
    box-sizing: border-box;
    border: 2px dashed rgba($grey-dark,0.5);

    @include mq ($mobileBreakpoint) {
        margin-top: 1.5em;
        border: 2px dashed $grey-dark;
    }

    &:hover {
        background: rgba($blue-color, 0.05);
        transition: background 110ms ease;
        cursor: pointer;
        border-color: $brand-color-light;

        svg path {
            fill: $brand-color-light;
        }

       .dz-message span {
            color: $brand-color-light;
        }
    }

    svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        max-width: 3em;
        top: -2.2em;

        @include mq($mobileBreakpoint) {
            max-width: 3em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            max-width: 3.5em;
        }

        path {
            fill: $grey-dark;
        }
    }


    .vue-dropzone {
        border: none;
        background: none;
    
        @include mq($mobileBreakpoint) {
            min-height: 10em;
        }
        
        @include mq($removeMobileMenuBreakPoint) {
            min-height: 12em;
        }
    
        &:hover {
            background: none;
        }
    
        &.dropzone .dz-preview {
            min-width: 8em;
    
            .dz-image {
                max-width: 100%;
                width: auto !important;
            }
    
            &:not(.dz-processing) .dz-progress {
                opacity: 0;
            }  
        }
    
        &.vue-dropzone>.dz-preview .dz-progress .dz-upload {
            display: none;
        }
    
        &.dropzone .dz-error-mark {
            display: none !important;
        }
        
        &.dropzone .dz-success-mark {
            top: 65%;
        
            svg path {
                fill: $white;
            }
        }
        
        &.dropzone .dz-preview.dz-error .dz-error-message {
            background: $negative;
            opacity: 1;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 2em;
        }
    
        &.dropzone >.dz-preview .dz-details {
            background-color: rgba($brand-color-light, 0.8);
        }
    
        &.dropzone >.dz-preview .dz-remove {
            @include btn($brand-color-light);
            background: $brand-color-light;
            color: $white;
            display: block;
            width: 100%;
            box-sizing: border-box;
            text-transform: inherit;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            opacity: 1;
        }
        
        .dz-message span {
            display: block;
            padding: 0.6em .8em;
            max-width: 8em;
            font-size: 0.8em;
            font-weight: bold;
            text-align: center;
            color: $grey-dark;
            text-transform: uppercase;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            top: 1em;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            max-height: 3em;
        
            @include mq($mobileBreakpoint) {
                padding: 0.8em;
                font-size: 1em;
            }
        
            @include mq($removeMobileMenuBreakPoint) {
                padding: 0.8em 1em;
            }
        }
    
    
        .dz-preview.dz-success {
            opacity: 0;
            transition: opacity 1s 2s ease;
        
            .dz-details {
                background-color: rgba($positive, 0.75);
                opacity: 1;
                transition: opacity 1s ease;
            }
        
            .dz-remove {
                display: none;
            }
        }
    
        .dz-progress {
            opacity: 0 !important;
        }
    }
}