.lead-form {
  @extend .wrapper;
  max-width: 425px;
  margin: 1em auto;

  .lead-form__fields {

    .label-input {
      margin-bottom: 1em;

      @include mq($mobileBreakpoint) {
        margin-bottom: 1.6em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        margin-bottom: 2.2em;
      }
    }
  }

  .lead-form__paragraph {
    display: block;
    max-width: 425px;
    text-align: center;
    margin: 0.5em auto 1em;
    font-size: 0.85em;
    color: $font-color;
    line-height: 150%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($mobileBreakpoint) {
      margin: 0.5em auto 1.2em;
      font-size: 0.9em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 0.5em auto 1.5em;
      font-size: 0.95em;
    }
  }

  .btn {
    margin-top: 1em;
    @include btn($positive);
    display: block;
    border-radius: 0;

    @include mq($mobileBreakpoint) {
      margin-top: 1.6em;
      padding: 1.01em 1em;
      font-size: 1.1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-top: 2.2em;
    }
  }

  .datapolicy-link {
    margin: 0.8em 0 0.5em;
    display: block;
    font-size: 0.9em;
    text-align: center;
    text-decoration: underline;
    color: rgba(desaturate($blue-color, 20%), 0.75);
    transition: color 110ms ease;

    @include mq($mobileBreakpoint) {
      margin: 1em 0 0.5em;
    }

    &:hover {
      color: $blue-color;
      transition: color 110ms ease;
    }
  }
}