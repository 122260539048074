.case-deposit {
    margin: 1em 0;

    @include mq($mobileBreakpoint) {
        margin-top: 2em;
        padding-right: 1em;
        padding-left: 1em;
        box-sizing: border-boxs;
    }

    h4 {
        margin-bottom: 0.2em;
        display: block;
        font-size: 1.1em;
        font-weight: normal;
        color: $font-color-dark;

        @include mq($mobileBreakpoint) {
            font-size: 1.2em;
            margin-bottom: 0.25em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 1.3em;
            margin-bottom: 0.4em;
        }

        @include mq($ExtraLargeBreakpoint) {
            font-size: 1.4em;
            margin-bottom: 0.5em;
        }
    }
  
    .case-deposit__line {
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-end;
        align-items: center;

        p {
            margin-left: 1em;
            color: shade($font-color-light, 30%);
            
            &:first-child {
                margin-right: auto;
                margin-left: 0;
            }
        }
        
        button {
            margin-left: 1em;
            background: none;
            border: 0;
            box-shadow: none;
            color: $blue-color;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}