.main-content {
  margin-top: 50px;
  margin-bottom: 7em;

  @include mq($removeMobileMenuBreakPoint) {
    margin-top: 77px;
    margin-bottom: 3em;
  }

  @at-root .main-app.main-app--case-view .main-content {
    margin: 0;

    @include mq($mobileBreakpoint) {
      margin-top: 1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-top: 5em;
    }
  }

  @at-root .main-app.main-app--hide-headers .main-content {
    margin: 0;
  }

  .main-content__wrapper {

    @include mq($mobileBreakpoint) {
      width: 100%;
      margin: 0 auto;
      padding: 0 1em;
      max-width: 40em;
      box-sizing: border-box;

      @include mq($removeMobileMenuBreakPoint) {
        max-width: 60em;
      }

      @include mq(976) {
        padding: 0;
      }
    }
  }

  .main-content__grid {

    @include mq($removeMobileMenuBreakPoint) {
      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .main-content__grid__col {

      @include mq($removeMobileMenuBreakPoint) {
        flex: 1;
        margin-right: 2em;
      }
    }

    .main-content__grid__sidebar {

      @include mq($removeMobileMenuBreakPoint) {
        //min-height: 50em;
        flex: 0.4;
        margin-top: 2em;
      }

      @include mq(1050) {
        flex: 0.5;
      }
    }
  }
}