.welcome-view {
    margin: 0 auto;
    max-width: 29em;
    padding: 1em;
    box-sizing: border-box;

    .welcome-view__title {
        font-size: 1.1em;
        text-align: center;

        @include mq($mobileBreakpoint) {
            font-size: 1.4em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 1.6em;
        }
    }

    .welcome-view__text {

    }

    p {
        font-size: 0.9em;
        line-height: 130%;

        @include mq($mobileBreakpoint) {
            font-size: 1em;
            line-height: 140%;
        }

        @include mq($removeMobileMenuBreakPoint) {
            line-height: 150%;
        }
    }

    .btn {
        @include btn($brand-color-light);
        display: block;
        margin: 2em auto 0;
        padding: 0.8em 2em;

        @include mq($mobileBreakpoint) {
            font-size: 0.9em;
            padding: 0.8em 2em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 1em;
        }
    }
}