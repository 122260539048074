.order-receipt {
    padding-bottom: 6em;
    box-sizing: border-box;

    @at-root .order-flow .order-receipt {
        padding: 0;
    }

    .company-content {
        margin-bottom: 2em;
    }

    .confirm-order-block {
        margin: 0 auto;
        max-width: 28em;
        padding: 1em;
        box-sizing: border-box;
        background: $white;
        box-shadow: $block-shadow;
        border-bottom: 1px solid $border-color;
    
        .confirm-order-block__heading {
          margin: 0;
          font-size: 1.2em;
        }
    
        .confirm-order-block__content {
    
          p {
            color: $font-color-dark;
            margin: 0.2em 0;
            font-size: 0.9em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
    
            span {
              font-weight: bold;
            }
          }
    
          button {
            background: none;
            border: none;
            box-shadow: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
            color: $blue-color;
          }
        }
    }
}