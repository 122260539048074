a {
  font-family:$font-standard;
  text-decoration: none;
  outline: 0;

  &:hover {
  }

  &.tel {
    color: inherit;
  }
}