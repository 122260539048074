.suggestion-list-component {
  position: relative;

  &.open {

    input {
      border-radius: $r-input;
    }

    .suggestion-list {
      display: block;
    }

    &.scroll .suggestion-list {
      @include mq($mobileBreakpoint) {
        min-height: 11em;
        max-height: 11em;
        overflow: auto;
      }
    }
  }

  ul.suggestion-list {
    position: absolute;
    top: 110%;
    right: 0;
    bottom: 0;
    left: 0;
    background: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: none;
    @include chrisCoyier;

    &:after {
      content: '';
      position: absolute;
      top: -0.4em;
      right: auto;
      bottom: auto;
      left: 1em;
      margin: auto;
      width: 0.75em;
      height: 0.75em;
      background: $white;
      transform: rotate(45deg);
      z-index: 91;
      border-top: 1px solid $border-color;
      border-left: 1px solid $border-color;
    }

    .suggestion-list__item {
      position: relative;
      background: $white;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      box-shadow: inset 0 1px 0 rgba($black,0.05), 0 1px 0 rgba($black,0.05);
      margin: 0;
      padding: 0.5em 1em;
      list-style: none;
      font-family: $font-standard;
      z-index: 90;
      overflow: hidden;

      &:hover, &.selected {
        background: tint($brand-color,98%);
        color: $brand-color;
        cursor: pointer;
        box-shadow: 0 1px 1px rgba($black,0.1),0 4px 4px rgba($black,0.05);
      }

      &:first-child {
        border-top: 1px solid $border-color;
        box-shadow: none;
      }

      &:last-child {
        border-bottom: 1px solid $border-color;
      }
    }
  }
}
