.intro-header {
  position: relative;
  display: block;
  background: $white;
  min-height: 8em;

  @include mq($removeMobileMenuBreakPoint) {
    min-height: 9em;
  }

  &.intro-header--profile {

    .content-block__heading {
      text-align: center;
    }
  }

  .intro-header__wrapper {
    @extend .wrapper;
    padding: 1em 0.5em;

    @include mq($mobileBreakpoint) {
      padding: 1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      padding: 2em 1em;
    }

    @include mq(976) {
      padding: 2em 0;
    }
  }

  .intro-block {

    @include mq($removeMobileMenuBreakPoint) {
      display: flex;
      flex-flow: wrap row;
      padding: 0.5em 0;
      background: $white;
      box-sizing: border-box;
    }

    .intro-block__image {
      display: none;

      @include mq($removeMobileMenuBreakPoint) {
        display: block;
        flex: 1;
        align-self: center;
        min-width: 9.2em;
        min-height: 9.2em;
        max-height: 9.2em;
        max-width: 9.2em;
        border: 3px solid $brand-color-light;
        border-radius: $r-round;
        position: relative;
        margin-right: 1.6em;

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 5em;
          height: auto;
        }
      }
    }

    .intro-block__content {

      @include mq($removeMobileMenuBreakPoint) {
        flex: 1;
      }
    }

    .intro-block__heading {
      margin: 0;
      font-size: 1.2em;
      text-align: center;
      color: $font-color-dark;

      @include mq($mobileBreakpoint) {
        font-size: 1.5em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        text-align: left;
        padding-top: 1.2em;
        font-size: 1.6em;
      }
    }

    .intro-block__product {
      margin: 0;
      font-size: 1em;
      font-weight: normal;
      text-align: center;
      color: tint($font-color-dark, 50%);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;


      @include mq($mobileBreakpoint) {
        font-size: 1.1em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        text-align: left;
        margin-top: 0.2em;
        font-size: 1.4em;
      }

      &.intro-block__product--transition-enter-active {
        animation: content-in 300ms ease both;
      }
      &.intro-block__product--transition-leave-active {
        animation: content-out 300ms ease both;
      }

      .intro-block__product__casenumber {
        display: block;
        margin: 0.5em auto;
        max-width: 10em;
        background: rgba($grey-dark, 0.5);
        color: $white;
        font-size: 0.8em;
        padding: 0.25em;
        box-sizing: border-box;
        border-radius: $r-btn;

        @include mq($removeMobileMenuBreakPoint) {
          transform: translate(0,-0.2em);
          margin-left: 0.5em;
          display: inline-block;
          font-size: 0.7em;
          text-align: left;
          padding: 0.35em 1em;
        }
      }
    }

    .intro-block__address {
      width: 80%;
      margin: 1em auto 0;
      position: relative;
      font-size: 1em;
      text-align: center;
      color: $brand-color-light;

      @include mq($mobileBreakpoint) {
        margin-top: 1em;
        font-size: 1.1em;
        width: 90%;
      }

      @include mq($removeMobileMenuBreakPoint) {
        text-align: left;
        margin-top: 0.2em;
        font-size: 1.5em;
        letter-spacing: 0.04em;
        width: 100%;
      }

      &.intro-block__address--transition-enter-active {
        animation: content-in 300ms ease both;
      }
      &.intro-block__address--transition-leave-active {
        animation: content-out 300ms ease both;
      }

      @at-root .intro-header .intro-header__wrapper.edit-mode .intro-block .intro-block__address {
        display: none;
      }

      .btn {
        @include icon-btn($brand-color-light);
        position: absolute;
        top: 0;
        right: -2.4em;
        bottom: 0;
        left: auto;
        margin: auto;

        @include mq($removeMobileMenuBreakPoint) {
          right: 0;
        }
      }
    }

    .intro-block__edit {
      display: none;
      @include chrisCoyier;

      @at-root .intro-header .intro-header__wrapper.edit-mode .intro-block .intro-block__edit {
        position: relative;
        display: block;
        margin-top: 1em;
        border-top: 1px solid $border-color;
      }

      .label-input {
        margin-top: 1em;
      }

      .input-fields__two {
        display: flex;
        flex-flow: wrap row;

        .label-input {
          flex: 1;

          &:first-child {
            margin-right: 0.5em;
            flex: 0.6;
          }

          &:last-child {
            margin-left: 0.5em;
          }
        }
      }

      .btn {
        @include icon-btn($grey-dark);
        position: absolute;
        top: -3em;
        right: 0;
        bottom: auto;
        left: auto;
        margin: auto;
        width: 2em;
        height: 2em;
        box-sizing: border-box;
        z-index: 1;
        cursor: pointer;

        @include mq($removeMobileMenuBreakPoint) {
          width: 3em;
          height: 3em;
          top: -4em;
        }

        .btn__icon {
          width: 1em;
          height: 1em;

          @include mq($removeMobileMenuBreakPoint) {
            width: 1.3em;
            height: 1.3em;
          }

          svg {
            fill: $white;
          }
        }
      }

      .intro-block__edit_save-btn {
        @include btn($positive);
        margin: 1em auto 0;
        display: block;
        width: 100%;

        @include mq($mobileBreakpoint) {
          max-width: 14em;
          float: right;
        }
      }
    }
  }
}