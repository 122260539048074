@import "../config";

html, body {
  overflow-x: hidden;
 // -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  background: $grey-light;

  .main-app {

    &.noScroll {
      position: fixed;
      width: 100%;
    }

    &.mobile-noScroll {
      position: fixed;
      width: 100%;

      @include mq($removeMobileMenuBreakPoint) {
        position: inherit;
        width: inherit;
      }
    }

    &.main-app--show-dda {
      background: $dda-bg;

      &:after {
        content: '';
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 100em;
        width: 100%;
        background: $dda-bg;
        z-index: -1;
      }
    }
  }
}