.order-help {
    margin: 1em auto 2em;
    max-width: 40em;
    padding: 1em;
    box-sizing: border-box;
    background: rgba($border-color, 0.2);
    border: 1px solid rgba($border-color, 0.3);

    @include mq($mobileBreakpoint) {
        padding: 1.5em;
        margin-bottom: 0;
    }

    .order-help__heading {
        margin: 0 0 0.8em;
        font-size: 1em;
        font-weight: normal;
        color: $font-color-light;
    }

    .order-help__text {
        font-size: 0.85em;
        line-height: 145%;
        color: $font-color-light;
        margin: 0.5em 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:last-child {
            margin-bottom: 0;
        }
    }
}