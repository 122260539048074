.kyc-wrapper {
    margin: 0 auto;
    background: $white;
    padding: 2em 2em 1em;
    box-sizing: border-box;
    max-width: 25.5em;

    &.loading {
        position: relative;

        &:before {
            position: fixed;
        }
    }

    &.kyc-wrapper--status {
        margin-top: 2em;
        
        p {
            line-height: 130%;
            text-align: center;
            -webkit-font-smoothing: inherit;
            -moz-osx-font-smoothing: inherit;
        }
    }
    
    .btn {
        display: block;
        margin: 0 auto;
        padding: 0.8em 2em;
        box-sizing: border-box;
        font-weight: bold;
        text-transform: inherit;
        background: $brand-color-light;
        border-radius: $r-btn;
        border: 1px solid $brand-color-light;
        color: $white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:hover {
            background: tint($brand-color-light, 10%);
            cursor: pointer;
        }

        &:disabled {
            opacity: 0.8;
            background: tint($grey-dark,40%) !important;
            color: shade($grey-light, 5%) !important;
            border-color: tint($grey-dark,35%) !important;
        
            &:hover {
                background: tint($grey-dark,40%) !important;
                color: shade($grey-light, 5%) !important;
                border-color: tint($grey-dark,35%) !important;
                cursor: not-allowed !important;
            }
        }
    }

    .kyc-wrapper__small-text {
        margin-top: 2em;
        font-size: 0.9em;
    }

    .kyc-wrapper__link {
        margin-top: 1em;
        display: block;
        text-align: center;
        text-decoration: underline;
        color: $brand-color-light;

        &.kyc-wrapper__link--inline {
            display: inline;
        }
    }
}