.order-item {
    position: relative;
    background: $white;
    padding: 1em;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;
    cursor: pointer;

    &:nth-child(odd) {
      background: tint($grey-light, 75%);
    }

    &:hover {
      background: rgba($blue-color, 0.05);
      box-shadow: inset 3px 0 0 $brand-color-light;
    }

    &.active {

      @include mq($removeMobileMenuBreakPoint) {
        background: rgba($blue-color, 0.05);
        box-shadow: inset 3px 0 0 $brand-color-light;
  
        .order-item__content {
  
          .order-item__caseid, .order-item__title {
            color: $brand-color-light;
          }
        }
      }
    }
  
    &.archived {
      opacity: 0.7;
      overflow: hidden;

      &:hover {
        opacity: 1;
      }

      &:after {
        content: 'Afsluttet';
        position: absolute;
        top: 0.9em;
        right: -2.2em;
        bottom: auto;
        left: auto;
        margin: auto;
        font-family: $font-standard;
        font-size: 0.8em;
        font-weight: bold;
        text-align: center;
        width: 8em;
        height: 1em;
        background: tint($grey-dark, 60%);
        box-shadow: 0 -1px 2px rgba($grey-dark, 0.1), 0 1px 2px rgba($grey-dark, 0.1);
        padding: 0.7em 0.6em 0.9em;
        transform: rotate(45deg);
        z-index: 1;

        @include mq($mobileBreakpoint) {
          top: 1.1em;
          right: -2em;
        }
      }
    }

    .order-item__icon {
        position: relative;
        display: block;
        flex: 1;
        min-width: 2em;
        max-width: 2em;
        min-height: 2em;
        max-height: 2em;
        padding: 0.5em;
        box-sizing: border-box;
        overflow: hidden;
        align-self: center;

        @include mq($mobileBreakpoint) {
          min-width: 2.5em;
          max-width: 2.5em;
          min-height: 2.5em;
          max-height: 2.5em;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 100%;
          height: auto;
        }
    }

    .order-item__content {
      align-self: center;
      margin-left: 1em;

      .order-item__caseid {
        font-size: 0.8em;
        color: rgba($font-color-dark, 0.8);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          font-size: 0.9em;
        }
      }

      .order-item__title {
        font-size: 0.8em;
        margin: 0 0 0.4em;
        color: $font-color-dark;

        @include mq($mobileBreakpoint) {
          font-size: 0.95em;
        }
      }

      p {
        margin: 0 0 0.2em;
        color: $font-color-dark;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 0.9em;

        @include mq($mobileBreakpoint) {
          font-size: 1em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1.1em;
        }

        span {
          color: $font-color-light;
        }
      }

      .order-item__link {
          color: $brand-color-light;
      }
    }

    .order-item__msg-counter {
      position: absolute;
      top: 0;
      right: 1em;
      bottom: 0;
      left: auto;
      margin: auto;
      padding: 0.5em 0.4em 0.5em;
      box-sizing: border-box;
      background: $brand-color-light;
      line-height: 50%;
      text-align: center;
      color: $white;
      border-radius: $r-btn;
      width: 1.5em;
      height: 1.5em;
    }
  }