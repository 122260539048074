.new-case {

  @include mq($removeMobileMenuBreakPoint) {
    flex: 1;
  }

  .new-case__heading {
    margin-top: 2.5em;
    margin-bottom: 0.2em;
    font-size: 1.5em;
    text-align: center;
    color: $brand-color-light;

    @include mq($smallMobileBreakpoint) {
      font-size: 1.7em;
    }

    @include mq($mobileBreakpoint) {
      font-size: 2em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      font-size: 2.2em;
    }

    @include mq($largeBreakpoint) {
      font-size: 2.4em;
    }
  }

  .new-case__subheading {
    margin: 0;
    font-size: 0.9em;
    text-align: center;
    font-weight: normal;
    color: $brand-color-light;

    @include mq($smallMobileBreakpoint) {
      font-size: 1em;
    }

    @include mq($mobileBreakpoint) {
      font-size: 1.1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      font-size: 1.2em;
    }

    @include mq($largeBreakpoint) {
      font-size: 1.3em;
    }
  }

  .new-case__grid {
    margin-top: 2.3em;
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-start;
    align-items: center;
  }

  .new-case-product {

    @include mq($mobileBreakpoint) {
      padding: 1em;
      box-sizing: border-box;
    }

    .new-case-product__header {
      display: flex;
      flex-flow: nowrap row;
      justify-content: flex-start;
      align-items: center;

      img {
        display: block;
        width: 5.5em;
        margin-right: 1em;
      }
    }

    p {
      line-height: 150%;
      color: $dda-text;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .new-case-product__body {
      margin-top: 2em;

      .book-meeting {
        width: 100%;
        margin-bottom: 1em;

        @include mq($mobileBreakpoint) {
          display: flex;
          flex-flow: nowrap row;
          justify-content: space-between;
          align-items: center;
        }

        .input-fields {
          width: 100%;
        }

        .label-input {
          padding: 0;
        }

        .datetime-picker {
          width: 100%;

          @include mq($mobileBreakpoint) {
            width: auto;
          }
    
          .label-input {
            display: flex;
            flex-flow: nowrap row;
            justify-content: space-between;
            align-items: center;
            border: 1px solid rgba($border-color, 0.4);
            box-shadow: $block-shadow;
            margin-right: 0;

            &:hover input {
              background: shade($white,2%);
              color: $brand-color-light;
              transition: background 200ms ease, color 200ms ease;
      
              &::-webkit-input-placeholder {
                color: $brand-color-light;
                transition: color 200ms ease;
              }
      
              &::-moz-placeholder {
                color: $brand-color-light;
                transition: color 200ms ease;
              }
      
              &:-ms-input-placeholder {
                color: $brand-color-light;
                transition: color 200ms ease;
              }
            }
      
            label {
              order: 0;
              position: relative;
              top: auto;
              left: 0;
              font-family: $font-standard;
              font-weight: bold;
              padding: 1.8em 1em 1.6em;
              display: block;
              flex: 1;
              width: 100%;
              height: 100%;
              font-size: 0.9em;
              min-width: 4.2em;
              max-width: 4.2em;
              color: rgba($font-color-dark, 0.8);
              box-sizing: border-box;
              background: rgba($grey-light,0.5);
              border-radius: 0;
              text-align: center;
              align-self: center;
              transform: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            input {
              font-family: $font-standard;
              font-size: 1em;
              color: $font-color-dark;
              padding: 1.4em 1em;
              order: 1;
              box-shadow: none;
            }
          }
        }

        .drop-down {
          margin-top: 1em !important;
          border: 1px solid rgba($border-color, 0.4);
          box-shadow: $block-shadow;

          @include mq($mobileBreakpoint) {
            margin-left: 0.5em !important;
          }

          input {
            font-family: $font-standard;
            font-size: 1em;
            padding: 1.6em 1em;
          }

          label {
            min-width: 4.2em;
            max-width: 4.2em;
            padding: 1.8em 1em 1.6em;
            font-weight: bold;
            font-size: 0.9em;
            border: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include mq($mobileBreakpoint) {
              min-width: 3.2em;
              max-width: 3.2em;
            }
          }
        }
      }
    }
  }

  .new-case__footer {
    padding: 0 1em;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 34em;

    p {
      margin: 0;
      display: block;
      text-align: center;
      font-size: 1.1em;
      color: $grey-dark;
      line-height: 150%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($mobileBreakpoint) {
        display: block;
        font-size: 1.25em;
      }
    }
  }
}