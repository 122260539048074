.faq-item {
    margin-top: 2em;
    background: $grey-light;

    &.faq-item--open {

        .faq-item__header .faq-item__arrow .faq-item__arrow__icon {
            transform:rotate(225deg) translate3d(-0.1em, -0.1em, 0);
            transition: transform 200ms ease;
        }

        .faq-item__body {
            display: block;
        }
    }

    .faq-item__header {
        display: flex;
        flex-flow: nowrap row;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        box-sizing: border-box;

        &:hover {
            cursor: pointer;
            background: rgba($white, 0.2);

            span {
                color: $brand-color-light;
            }


            .faq-item__arrow .faq-item__arrow__icon {
                border-color: $brand-color-light;
            }
        }
    }

    .faq-item__arrow {
        position: relative;
        display: block;
        width: 1em;
        height: 1em;
        margin-left: auto;

        .faq-item__arrow__icon {
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border: 2px solid $black;
            box-sizing: border-box;
            border-top: none;
            border-left: none;
            z-index:2;
            transform:rotate(45deg) translate3d(-0.1em, -0.1em, 0);
            transition: transform 200ms ease;
        }
    }

    .faq-item__body {
        border-top: 1px solid $border-color;
        padding: 1em;
        box-sizing: border-box;
        display: none;

        p {
            margin: 0;
            line-height: 150%;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}