.nem-rights-page {

  .my-rights-intro {
    background: $white;
    border: 1px solid $border-color;
    margin: 1em auto 2em;
    width: 100%;
    padding: 1.2em 1em;
    box-sizing: border-box;

    @include mq($mobileBreakpoint) {
      margin: 1.5em auto 3em;
      padding: 1.5em 1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 2em auto 4em;
      padding: 1.5em;
    }

    .my-rights-intro__header {
      display: flex;
      flex-flow: nowrap row;
      margin-bottom: 0.2em;

      @include mq($smallMobileBreakpoint) {
        margin-bottom: 0.4em;
      }

      @include mq($mobileBreakpoint) {
        margin-bottom: 1em;
      }

      .my-rights-intro__header__text {
        margin: 0;
        flex: 1;
        align-self: center;
        font-size: 1.1em;
        font-weight: normal;
        color: $brand-color-light;

        @include mq($smallMobileBreakpoint) {
          font-size: 1.2em;
        }

        @include mq($mobileBreakpoint) {
          font-size: 1.5em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1.8em;
        }
      }

      .my-rights-intro__header__icon {
        flex: 1;
        min-width: 2.5em;
        max-width: 2.5em;
        min-height: 2.5em;
        max-height: 2.5em;
        margin-right: 0.5em;

        @include mq($smallMobileBreakpoint) {
          min-width: 3em;
          max-width: 3em;
          min-height: 3em;
          max-height: 3em;
          margin-right: 0.8em;
        }

        @include mq($mobileBreakpoint) {
          min-width: 4em;
          max-width: 4em;
          min-height: 4em;
          max-height: 4em;
          margin-right: 1em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          min-width: 6em;
          max-width: 6em;
          min-height: 6em;
          max-height: 6em;
          margin-right: 1.2em;
        }
      }
    }

    p {
      line-height: 150%;
    }

    .btn {
      @include btn($positive);
    }
  }

  .faq-heading {
    margin: 0.5em;

    @include mq($mobileBreakpoint) {
      margin: 0.5em 0;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-bottom: 1em;
    }
  }
}