.id-item {
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid $border-color;
    border-radius: $r-btn;
    transition: border-color 400ms ease;

    &:hover {
        cursor: pointer;
        border: 1px solid rgba($brand-color-light, 0.3);
        transition: border-color 110ms ease;

        .id-item__text, .id-item__small-text {
            color: $brand-color-light;
            transition: color 110ms ease;
        }

        .id-item__validation-icon .id-item__validation-icon__line {
            border-right-color: $brand-color-light;
            border-bottom-color: $brand-color-light;
            transition: all 110ms ease;
        }
    }

    &.id-item--checked {

        &:hover {
            cursor: default;
            border: 1px solid $border-color;
            transition: border-color 110ms ease;
    
            .id-item__text, .id-item__small-text {
                color: $font-color;
                transition: color 110ms ease;
            }
    
            .id-item__validation-icon .id-item__validation-icon__line {
                border-right-color: $positive;
                border-bottom-color: $positive;
                transition: all 110ms ease;
            }
        }

        .id-item__validation-icon .id-item__validation-icon__line {
            width: 10px;
            height: 16px;
            transform: rotate(45deg) translateY(-4px);
            border-right-color: $positive;
            border-bottom-color: $positive;
            transition: all 200ms ease;
        }
    }

    .id-item__type-icon {
        display: block;
        width: 3.2em;
        height: 3.2em;
    }

    .id-item__text {
        display: block;
        margin-left: 1em;
        font-size: 1.1em;
    }

    .id-item__small-text {
        display: block;
        margin-top: 0.1em;
        margin-left: 1.5em;
        font-size: 0.75em;
    }

    .id-item__validation-icon {
        position: relative;
        display: block;
        width: 2em;
        height: 2em;
        margin-left: auto;

        .id-item__validation-icon__line {
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border: 2px solid $black;
            box-sizing: border-box;
            border-top: none;
            border-left: none;
            z-index:2;
            transform:rotate(-45deg);
            transition: all 200ms ease;
        }
    }
}