.shared-files {
  display: none;

  @include mq($removeMobileMenuBreakPoint) {
    display: block;
  }

  h2 {
    font-size: 1.3em;
    margin: 0 0 0.5em;
  }

  .shared-files__list {
    border: 1px solid $border-color;
    border-radius: $r-btn $r-btn 0 0;
    margin: 0 0 1em;

    h3 {
      padding: 1em;
      margin: 0;
      background: $white;
      border-bottom: 1px solid $border-color;
      border-radius: $r-btn $r-btn 0 0;
      font-size: 1.05em;
      color: $font-color-dark;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .shared-file-item {
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    background: $white;
    padding: 1em;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba($font-color-light, 0.05), inset 0 -1px rgba($font-color-light, 0.1);
    transform: translate3d(0,0,0) scale(1);
    transition: transform 200ms ease, box-shadow 200ms ease;

    &:hover {
      transform: translate3d(0,0,0) scale(1.02);
      box-shadow: 0 2px 4px rgba($font-color-light, 0.1), 0 12px 40px rgba($font-color-light, 0.15);
      transition: transform 200ms ease, box-shadow 200ms ease;
      z-index: 1;
    }

    img {
      flex: 1;
      align-self: center;
      min-width: 2em;
      max-width: 2em;
      min-height: 2em;
      max-height: 2em;
      margin-right: 1em;

      @include mq($removeMobileMenuBreakPoint) {
        margin: 0 1.2em 0 0.2em;
      }
    }

    p {
      margin: 0;
      flex: 1;
      align-self: center;
      display: block;
      color: $brand-color;

      span {
        display: block;

        &:last-child {
          font-size: 0.85em;
          color: $font-color-light;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
}