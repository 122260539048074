.case-documents {
    width: 100%;
    margin-bottom: 4.5em;
    background: $white;

    h3 {
        margin: 0 0 0.6em;
        font-size: 1.1em;

        @include mq($mobileBreakpoint) {
            margin: 0.2em 0 0.8em;
            text-align: left;
            font-size: 1.17em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            margin: 0.6em 0 1.2em;
        }
    }

    .case-documents_text {
        padding: 1em 0;
        box-sizing: border-box;

        @include mq($removeMobileMenuBreakPoint) {
            padding: 2em 0;
        }
                
        p {
            max-width: 15.5em;
            padding: 0 1em;
            box-sizing: border-box;
            display: block;
            margin: 0 auto;
            text-align: center;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            a {
                color: $brand-color-light;
                text-decoration: underline;
            }
        }
    }
}