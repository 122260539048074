.intro-slide {
  @extend .wrapper;
  margin: 4em auto 0;
  max-width: 30em;
  position: relative;

  @include mq($removeMobileMenuBreakPoint) {
    margin-top: 6em;
    max-width: 40em;
  }

  .intro-slide__heading {
    font-size: 1.05em;
    text-align: center;
    padding: 0 1em;
    margin-bottom: 0.4em;

    @include mq($mobileBreakpoint) {
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      padding: 0 2em;
      font-size: 1.8em;
    }
  }

  .intro-slide__text {
    text-align: center;
    font-size: 0.9em;
    margin-top: 0;

    @include mq($mobileBreakpoint) {
      font-size: 1.1em;
      line-height: 140%;
    }

    @include mq($removeMobileMenuBreakPoint) {
      font-size: 1.2em;
    }
  }

  .intro-slide__actions {
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    margin: 2em 0 1em;

    @include mq($removeMobileMenuBreakPoint) {
      margin-top: 2em;
    }

    .btn {
      @include btn($brand-color-light);
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1em;
      }

      &.btn--negative {
        @include btn($negative);
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1em;
        }
      }

      &.btn--positive {
        @include btn($positive);
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($smallMobileBreakpoint) {
          font-size: 0.9em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1em;
        }
      }

      &:first-child {
        margin-right: 0.5em;
        min-width: 8em;

        @include mq($removeMobileMenuBreakPoint) {
          min-width: 8em;
        }
      }

      &:last-child {
        margin-left: 0.5em;
        min-width: 8em;

        @include mq($removeMobileMenuBreakPoint) {
          min-width: 8em;
        }
      }
    }
  }

  .intro-slide__skip {
    width: 6em;
    position: absolute;
    bottom: -2em;
    left: 0;
    margin: auto;
    font-size: 0.75em;
    color: rgba($font-color-light, 0.8);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($mobileBreakpoint) {
      bottom: -1.6em;
      font-size: 0.8em;
    }
  }
}