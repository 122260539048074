.edit-text {
  position: relative;
  display: block;
  padding: 0.2em 0;
  width: 100%;
  color: $brand-color-light;
  background: rgba($blue-color,0.05);
  border-bottom: 1px dashed rgba($blue-color,0.3);

  &:hover {
    background: rgba($blue-color,0.1);
    border-bottom: 1px dashed rgba($blue-color,0.4);
    color: $blue-color;

    &:before {
      transform: translate3d(0,-135%,0);
      transition: transform 200ms ease;
    }
  }
}