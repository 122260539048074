.drop-down {
    flex: 1;
    display: flex;
    margin: 0 auto;
    position: relative;

    &:first-child {
      flex: 1.23;

      label {
        border-left: none;
      }
    }

    &:last-child  {
      border-left: 1px solid $border-color;
    }

    label {
      font-family: $font-standard;
      margin: 0;
      padding: 1.3em 1em;
      display: block;
      flex: 1;
      width: 100%;
      font-size: 1em;
      min-width: 3.2em;
      max-width: 3.2em;
      color: rgba($font-color-dark, 0.8);
      box-sizing: border-box;
      border-right: 1px solid $border-color;
      border-left: 1px solid $border-color;
      background: rgba($grey-light,0.5);
      border-radius: 0;
      text-align: center;
      align-self: center;
    }

    .drop-down__select {
      font-family: $font-standard;
      flex: 1;
      margin: 0 auto;
      padding: 0.9em 1em;
      display: block;
      width: 100%;
      font-size: 1em;
      color: $font-color-dark;
      background: $white;
      cursor: text;
      border: 1px solid transparent;
      box-sizing: border-box;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
      border-radius: 0;

      &::-ms-expand {
        display: none;
      }

      &::-ms-value {
        background: transparent;
        color: $brand-color-light;
      }

      &:hover {
        background: shade($white,2%);
        color: $brand-color-light;
        transition: background 200ms ease, color 200ms ease;

        &::-webkit-input-placeholder {
          color: $brand-color-light;
          transition: color 200ms ease;
        }

        &::-moz-placeholder {
          color: $brand-color-light;
          transition: color 200ms ease;
        }

        &:-ms-input-placeholder {
          color: $brand-color-light;
          transition: color 200ms ease;
        }
      }

      &:focus:hover {
        color: $brand-color-light;
        background: tint($brand-color-light,92%);
        transition: background 200ms ease, color 200ms ease, border 280ms ease;
      }
    }
  }