.validate-modal {

    .validate-modal__box {
        margin-bottom: 1em;

        @include mq($removeMobileMenuBreakPoint) {
            background: $white;
            padding: 1em;
            box-sizing: border-box;
            box-shadow: $block-shadow;
        }

        p {
            line-height: 150%;

            a {
                color: $blue-color;
                text-decoration: underline;
            }
        }
    }
}