.dda-header {
    background: white;
  
    .nem-wrapper {
      max-width: 75em;
      margin: 0 auto;
      padding: 25px 1em;
      box-sizing: border-box;

      img {
        max-width: 180px;
      }
    }
}