// Base colors
$black:#000;
$white:#fff; //BG compatible

// Shades
$grey-light: #eee; //BG compatible
$grey-dark: shade($grey-light,50%);
$grey: #464646;
$border-color: #dfdede;

// Colors
$brand-color: #0D2B42; //BG compatible
$brand-color-light: #0f436b;
$blue-color: #337bb5;
$yellow-color: #cf7e3c;
$purple-color: #993d87;
$st-color: #365c4d;

// Nem24 Colors
$nem24-brand-color: #FD6101;
$nem24-border-color:#ebebeb;
$nem24-grey: #c8c8c8;
$nem24-grey-light: #f7f7f7;
$nem24-grey-dark: #545f65;
$nem24-black:#111;
$nem24-success: #27C966;
$nem24-error: #F4C7C7;

//DDA Colors
$dda-text: #19222d;
$dda-bg: #deebed;
$dda-footer: #1f2429;

//Leadblik status
$pale-green: #d2e9d9;
$pale-blue: #dce8eb;
$pale-red: #f7e4e5;

// System Colors
$positive:#24a354;
$positive-light:tint($positive,30%);
$negative:#d40e17;
$negative-light:tint($negative,30%);
$informative:$blue-color;
$informative-light: tint($blue-color,30%);
$warning:yellow;
$warning-light:tint(yellow,30%);

// Font colors
$font-color:$black;
$font-color-dark:#1e1714;
$font-color-light: tint($font-color-dark,50%);
$font-color-white:$white;
$font-color-blue: #11426d; //Links and headings compatible

// Font family settings
$font-standard: 'Lato', sans-serif;
$font-bold: 'Lato', sans-serif;
$font-nem24: 'Switzer-sariable', sans-serif;

// border radius
$r:0;
$r-btn:4px;
$r-round: 50%;
$r-input: 0%;

// remove mobile menu breakpoint
$smallMobileBreakpoint: 330;
$mobileBreakpoint: 600;
$removeMobileMenuBreakPoint: 900;
$largeBreakpoint: 1100;
$ExtraLargeBreakpoint: 1300;

// Transitions
$hover-time-enter: 120ms;
$hover-time-leave: 350ms;
$hamburgerTransition: all 650ms ease;

// Animations
$loading-jump-distance: -2px;

// Shadows
$block-shadow: 0 2px 4px rgba($brand-color, 0.05);
$block-shadow-large: 0 2px 4px rgba($brand-color, 0.05), 0 12px 40px rgba($brand-color, 0.1);