.case-product {
    margin-top: 1em;

    @include mq($mobileBreakpoint) {
        margin-left: 0.5em;
    }


    .case-product__heading {
        margin: 0;
        font-size: 0.85em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .case-product__text {
        margin: 0.1em 0 0;
        font-size: 1.1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}