
  .message-write-box {
    width: 100%;

    .msg-field {
      position: relative;
      display: flex;
      flex-flow: nowrap row;

      &.msg-field--open {

        .msg-field__label {
          opacity: 0;
          transition: opacity 110ms ease;
        }

        .msg-field__textarea {
       
          @include mq($removeMobileMenuBreakPoint) {
            height: 8em;
          }
        }
      }

      &.msg-field--has-text .msg-field__label {
        opacity: 0;
        transition: opacity 110ms ease;
      }

      .msg-field__label {
        position: absolute;
        top: 0;
        right: 3.4em;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 50%;
        height: 1.1em;
        font-family: $font-standard;
        font-size: 0.8em;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        color: rgba($grey-dark, 0.8);
        opacity: 1;
        transition: opacity 110ms ease;

        @include mq($mobileBreakpoint) {

        }
      }

      .msg-field__textarea {
        height: 2.9em;
        display: block;
        flex: 1;
        margin: 0;
        padding: 0.8em 1em;
        font-size: 0.9em;
        border: 1px solid $border-color;
        border-right: none;
        border-radius: $r-btn 0 0 $r-btn;
        box-sizing: border-box;
        -webkit-appearance: none;
      }

      .msg-field__button {
        margin: 0;
        padding: 0;
        display: block;
        flex: 1;
        max-width: 4em;
        background: $blue-color;
        color: $white;
        border: 1px solid $blue-color;
        box-shadow: none;
        box-sizing: border-box;
        border-radius: 0 $r-btn $r-btn 0;
      }
    }

    .input-group {
      display: block;
      width: 100%;

      > input {
        border: none;
        -webkit-appearance: none;
      }
    }
  }