.login-selector {
    @extend .wrapper;
    padding: 2em 1em 5em;

    @include mq($mobileBreakpoint) {
        padding-top: 0;
        padding-bottom: 12em;
    }

    .login-selector__heading {
        font-size: 1.5em;
        text-align: left;
        max-width: 12em;
        margin: 0;
        color: $dda-text;

        @include mq($mobileBreakpoint) {
            margin: 1.8em auto 0;
            font-size: 2em;
            text-align: center;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 2.2em;
            line-height: 130%;
        }
    }

    .login-selector__text {
        text-align: left;

        @include mq($mobileBreakpoint) {
            text-align: center;
        }

        @include mq($removeMobileMenuBreakPoint) {
            margin-bottom: 2em;
        }
    }

    .login-tile-grid {
        display: flex;
        flex-flow: nowrap row;
        justify-content: center;
        align-items: center;

        @include mq($mobileBreakpoint) {
            margin-top: 1.5em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            margin-top: 2em;
        }
    }

    .login-tile {
        flex: 1;
        padding: 1.5em;
        box-sizing: border-box;
        background: rgba($white, 0.25);
        box-shadow: $block-shadow;
        transition: transform 200ms ease;

        @include mq(450) {
            padding: 2em;
        }

        @include mq($mobileBreakpoint) {
            flex: inherit;
            padding: 2.5em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            padding: 3em;
        }

        &:first-child {
            margin-right: 0.5em;

            @include mq($mobileBreakpoint) {
                margin-right: 1em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                margin-right: 2em;
            }
        }

        &:last-child {
            margin-left: 0.5em;

            @include mq($mobileBreakpoint) {
                margin-left: 1em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                margin-left: 2em;
            }
        }

        &:hover {
            box-shadow: $block-shadow-large;
            transform: scale(1.02);
            transition: transform 120ms ease;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 6em;

            @include mq(450) {
                max-width: 7em;
            }

            @include mq($mobileBreakpoint) {
                max-width: 8em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                max-width: 9em;
            }
        }

        .login-tile__text {
            display: block;
            margin: 0.5em auto 0;
            color: $dda-text;
            font-size: 1em;
            text-align: center;

            @include mq(450) {
                font-size: 1.2em;
            }

            @include mq($mobileBreakpoint) {
                font-size: 1.25em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                font-size: 1.35em;
            }
        }
    }
}