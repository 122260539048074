.btn {

  &.btn--loading {
    overflow: hidden;
    position: relative;
    cursor: wait;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      min-width: 30em;
      width: 100%;
      height: 20em;
      background: rgba($black,0.2);
      transform: translate3d(-100%,0,0);
      animation: loading-progress 6s linear both;
      z-index: 1;
    }
  }
}