.case-overview {
    margin-bottom: 4.5em;
    
    @include mq($mobileBreakpoint) {
        margin-bottom: 5.8em;
    }

    .case-overview__block {
        padding: 0.8em 1em 0.5em;
        box-sizing: border-box;
        background: $white;
        box-shadow: $block-shadow;
        
        @include mq($largeBreakpoint) {
            padding: 1.2em 1.5em 0.5em;
        }
    }

    h3 {
        margin: 0.1em 0 0;
        display: block;
        font-size: 1.1em;
        color: $font-color-dark;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
            margin-left: 0.5em;
            margin-top: 0.25em;
            font-size: 1.2em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 1.25em;
        }
    }
}