.input-fields {

  .input-fields__heading {
    margin: 0;
    color: $brand-color-light;
    font-size: 1.1em;

    @include mq($mobileBreakpoint) {
      font-size: 1.2em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      font-size: 1.4em;
    }
  }

  .input-fields__two {
    display: flex;
    flex-flow: wrap row;

    .label-input {
      flex: 1;

      &:first-child {
        margin-right: 0.5em;
        flex: 0.6;
      }

      &:last-child {
        margin-left: 0.5em;
      }
    }
  }

  .label-input {
    margin: 1em 0 0;
  }

  .input-fields__actions {
    margin-top: 1em;
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    width: 100%;

    .btn {
      &:first-child {
        @include btn($grey-dark);
        min-width: 48%
      }

      &:last-child {
        @include btn($brand-color-light);
        min-width: 48%
      }
    }
  }
}