.case-droppoint {
    @import './../../../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css';
    width: 100%;
    background: $white;
    padding: 1em;
    margin-bottom: 4.5em;
    box-sizing: border-box;

    @include mq($largeBreakpoint) {
        padding: 2em;
    }

    h3 {
        margin: 0;
        font-size: 1.1em;

        @include mq($mobileBreakpoint) {
            margin: 0.2em 0 0.8em;
            font-size: 1.17em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            margin: 0.6em 0 1.2em;
        }
    }
    .case-droppoint__text {
        margin: 0 0 1em;

        .case-droppoint__text__success {
            color: $positive;
        }

        .case-droppoint__text__error {
            color: $negative;
        }
                
        p {
            padding: 0 0.5em;
            box-sizing: border-box;
            display: block;
            margin: 0 0 1em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include mq($mobileBreakpoint) {
                padding: 0;
                margin-bottom: 1.2em;
            }
        }

        .btn {
            @include btn($positive);
            display: block;
            margin: 1em auto;
            padding: 0.8em 2em !important;

            @include mq($mobileBreakpoint) {
                margin: 1.2em auto 0.8em;
                font-size: 1em;
            }
    
            @include mq($removeMobileMenuBreakPoint) {
                margin: 1.5em auto 0.8em;
            }

            &.loading:after {
                display: none;
            }
        }

        a {
            display: block;
            text-align: center;
            font-size: 0.9em;
            letter-spacing: 0.01em;
            color: rgba($grey-dark, 0.5);
        }
    }
}