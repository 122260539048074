.list {
  margin-top: 1em;
  border: 1px solid $border-color;

  @include mq($removeMobileMenuBreakPoint) {
    margin-top: 2em;
  }

  .list-item {
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
    padding: 0.85em 0;
    align-items: center;
    background: $white;
    border-bottom: 1px solid $border-color;

    &:hover {
      background: tint($grey-light,70%);

      .list-item__content {

        .list-item__heading, .list-item__text{
          color: $blue-color;
        }
      }
    }

    .list-item__icon {
      position: relative;
      flex:1;
      min-width: 56px;
      max-width: 56px;
      min-height: 50px;

      &.list-item__icon--left {

      }

      &.list-item__icon--left {

      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;
        border: none;
      }
    }

    .list-item__content {
      flex: 1;

      .list-item__heading {
        margin: 0;
        font-size: 0.9em;
        color: $brand-color-light;

        @include mq($mobileBreakpoint) {
          font-size: 0.95em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1em;
        }
      }

      .list-item__text {
        margin: 0;
        font-size: 0.8em;
        color: $font-color-light;

        @include mq($mobileBreakpoint) {
          margin-top: 0.1em;
          font-size: 0.85em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 0.9em;
        }
      }
    }
  }
}