.datetime-picker {
  position: relative;
  font-family: $font-standard;
  -webkit-font-smoothing: antialiased;

  input.working {
    pointer-events: none;
    cursor: progress;
  }

  .label-input:after {
    //background: url("../assets/img/icons/icon-select-arrow.svg") no-repeat;
    content: '';
    position: absolute;
    top: auto;
    left: auto;
    bottom: 18px;
    right: 1.4em;
    margin: auto;
    width: 1.1em;
    min-width: 1.1em;
    max-width: 1.1em;
    height: 0.8em;
    min-height: 0.8em;
    max-height: 0.8em;
    pointer-events: none;
  }
}

.date-picker {
  width: 100.1% !important;
}

.datetime-picker * {
  box-sizing: border-box;
}

.datetime-picker .picker-wrap {
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-width: 32em;
  height: 280px;
  margin-top: 2px;
  background-color: #fff;
  box-shadow: 0 0 6px #ccc;
  top:3em;

  @include mq($mobileBreakpoint) {
    top:4.2em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    top:4.4em;
    left: 0.5em;
  }
}

.datetime-picker table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  font-size: 13px;
}

.datetime-picker tr {
  height: 34px;
  border: 0 none;
}

.datetime-picker th, .datetime-picker td {
  user-select: none;
  width: 34px;
  height: 34px;
  padding: 0;
  border: 0 none;
  line-height: 34px;
  text-align: center;
}

.datetime-picker td {
  cursor: pointer;
  color: $font-color;
  transition: color 400ms ease, background 500ms ease;
}

.datetime-picker td:hover {
  background-color: rgba($brand-color,0.1);
  color: $brand-color;
  transition: color 20ms ease, background 20ms ease;
}

.datetime-picker td.date-pass, .datetime-picker td.date-future {
  color: lighten($font-color,50%);
  cursor: default;

  &:hover {
    background-color: transparent;
    color: lighten($font-color,50%);
  }
}

.datetime-picker td.date-active {
  background-color: $brand-color;
  color: $white;
}

.datetime-picker .date-head {
  background-color: $brand-color;
  text-align: center;
  color: $white;
  font-size: 14px;
}

.datetime-picker .date-days {
  color: $font-color;
  font-size: 14px;
}

.datetime-picker .show-year {
  display: inline-block;
  min-width: 62px;
  vertical-align: middle;
}

.datetime-picker .show-month {
  display: inline-block;
  min-width: 28px;
  vertical-align: middle;
}

.datetime-picker .btn-prev,
.datetime-picker .btn-next {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  vertical-align: middle;
}

.datetime-picker .btn-prev:hover,
.datetime-picker .btn-next:hover {
  background: $brand-color;
}