.info-links {

    p {
        margin: 0;
        text-align: center;
        color: rgba($grey-dark, 0.5);

        span {
            margin: 0 0.2em;
        }

        a {
            color: rgba($grey-dark, 0.5);
            font-size: 0.9em;
            letter-spacing: 0.01em;
        }
    }
}