.lead-view {

    //modals adjustments for lead view
    .modal-box  {
      
        .modal-box__content {
            background: tint($grey-light, 30%);
        }

        .modal-box__header {
            min-height: 47px;
            max-height: 47px;

            @include mq($mobileBreakpoint) {
                min-height: 57px;
                max-height: 57px;
            }

            @include mq($removeMobileMenuBreakPoint) {
                min-height: 63px;
                max-height: 63px;
            }
        }

        .modal-box__header__left {
            width: 100%;

            .modal-box__header__heading {
                text-align: center;

                @include mq($removeMobileMenuBreakPoint) {
                    text-align: left;
                }
            }
        }

        .modal-box__header__right {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            margin: auto;
            min-width: 47px;
            max-width: 47px;
            min-height: 47px;
            max-height: 47px;

            @include mq($mobileBreakpoint) {
                min-width: 57px;
                max-width: 57px;
                min-height: 57px;
                max-height: 57px;
            }

            @include mq($removeMobileMenuBreakPoint) {
                min-width: 63px;
                max-width: 63px;
                min-height: 63px;
                max-height: 63px;
            }

            .modal-box__header__btn {
                min-width: 47px;
                max-width: 47px;
                min-height: 47px;
                max-height: 47px;
                margin: 0;
                padding: 0;
                box-sizing: border-box;

                @include mq($mobileBreakpoint) {
                    min-width: 57px;
                    max-width: 57px;
                    min-height: 57px;
                    max-height: 57px;
                }
    
                @include mq($removeMobileMenuBreakPoint) {
                    min-width: 63px;
                    max-width: 63px;
                    min-height: 63px;
                    max-height: 63px;
                }

                svg {
                    position: relative;
                    top: 0.1em;
                    width: 16px;
                    height: 16px;

                    @include mq($mobileBreakpoint) {
                        width: 18px;
                        height: 18px;
                    }

                    @include mq($removeMobileMenuBreakPoint) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .lead-view__wrapper {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
      
        @include mq($removeMobileMenuBreakPoint) {
          max-width: 45em;
          margin-top: 2em;
        }
      
        @include chrisCoyier;

        .lead-view__heading {
            margin: 0 0 1em;
            text-align: center;
            font-size: 1.2em;

            @include mq($smallMobileBreakpoint) {
                font-size: 1.3em;
            }

            @include mq($mobileBreakpoint) {
                font-size: 1.5em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                font-size: 2em;
                margin-bottom: 1.2em;
            }
        }
    }
    
    .lead-info {
        position: relative;
        padding: 1em;
        background: $white;
        box-sizing: border-box;

        @include mq($mobileBreakpoint) {
            padding: 2em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            padding: 2em;
        }

        &.lead-info--log-messages {
            padding: 2em 2em 8em;
            overflow: auto;
            height: calc(100vh - 7.7em);

            @include mq($removeMobileMenuBreakPoint) {
                margin: 8em 0 7em;
                padding: 1em 2em 2em;
                overflow: inherit;
                height: auto;
            }
        }

        p { 
            margin: 0 0 1em;
            line-height: 150%;

            @include mq($removeMobileMenuBreakPoint) {
                font-size: 1.1em;
            }

            a {
                color: $blue-color;
            }
        }


        .lead-id {
            position: absolute;
            top: -0.2em;
            right: 0.5em;
            bottom: auto;
            left: auto;
            margin: auto;
            margin: 0.6em 0.25em 0;
            font-size: 0.8em;
            display: block;
            text-align: right;
            letter-spacing: 0.025em;
            color: rgba($grey, 0.75);

            @include mq($mobileBreakpoint) {
                font-size: 0.85em;
                top: 1em;
                right: 2em;
            }
        }
    }

    .related-products {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 1em;
        box-sizing: border-box;

        @include mq($mobileBreakpoint) {
            padding: 0 2em 2em;
        }
      
        @include mq($removeMobileMenuBreakPoint) {
          max-width: 45em;
          padding: 1em 2em 2em;
        }

        .related-products__heading {
            margin: 1em 0 0;
            font-size: 1em;
            text-align: center;
    
            @include mq($smallMobileBreakpoint) {
                font-size: 1.2em;
            }
    
            @include mq($mobileBreakpoint) {
                font-size: 1.3em;
                text-align: left;
            }
        }

        .lead-products {
            margin: 0;
            
            .lead-product {
                background: rgba($white, 0);
                border: 2px dashed $border-color;
                transition: transform 200ms ease, opacity 200ms ease, border 200ms ease;

                &:hover {
                    border-color: transparent;
                    transition: transform 200ms ease, opacity 200ms ease, border 200ms ease;
                }
            }
        }
    }

    .lead-products {

        @include mq($mobileBreakpoint) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 1em;
            column-gap: 1.5em;
            row-gap: 1.5em;
        }

        .lead-product {
            position: relative;
            margin-bottom: 1em;
            padding: 1em;
            box-sizing: border-box;
            background: tint($grey-light, 40%);
            cursor: pointer;
            border: 1px rgba($border-color,0.2) solid;
            transform: none;
            opacity: 1;
            transition: transform 200ms ease, opacity 200ms ease;

            @include mq($smallMobileBreakpoint) {
                padding: 1.5em;
            }

            @include mq($mobileBreakpoint) {
                margin: 0;
                padding: 2em;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.lead-product--verified img {
                position: absolute;
                top: 1em;
                right: 1em;
                bottom: auto;
                left: auto;
                margin: auto;
                width: 1em;
                height: 1em;
                display: block;
            }

            &:hover {
                background: shade($white, 2%);
                transform: translate3d(0,0,0) scale(1.02);
                box-shadow: 0 2px 4px rgba($brand-color, 0.1), 0 12px 40px rgba($brand-color, 0.12);
                transition: transform 200ms ease, opacity 200ms ease, box-shadow 200ms ease;

                .lead-product__content .btn {
                    background: tint($positive,20%);
                    border: 1px solid rgba($positive, 0.6);
                }
            }

            img {
                display: none;
            }

            .lead-product__content {
                display: flex;
                flex-flow: nowrap column;
                justify-content: flex-start;
                align-items: center;
                height: 100%;

                h4 {
                    text-align: center;
                    margin: auto 0 auto;

                    span {
                        display: block;
                        font-weight: normal;
                        color: rgba($font-color-dark, 0.75);
                    }
                }

                            
                .btn {
                    @include btn($positive);
                    display: block;
                    margin: 1em auto 0;
                    text-transform: inherit;
                    color: $white;
                    font-weight: normal;
                    min-width: 12.5em;

                    @include mq($mobileBreakpoint) {
                        padding-right: 1.2em;
                        padding-left: 1.2em;
                    }

                    &.btn--filled {
                        position: relative;
                        text-align: left;
                        display: flex;
                        flex-flow: nowrap row;
                        justify-content: flex-start;
                        align-items: center;
                        background: $white;
                        overflow: hidden;

                        &:hover {
                            background: $white;
                            border-color: $positive;
                        }

                        span {
                            color: $black;
                            display: block;
                            text-align: center;
                            box-sizing: border-box;
                            color: shade($positive, 10%);
                        }
                    }

                    span {
                        text-align: center;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .lead-comment {

        p {
            line-height: 150%;
        }

        textarea {
            min-height: 20em;
        }

        .btn {
            @include btn($positive);
            display: block;
            margin: 1em auto 0;
            text-transform: inherit;
            color: $white;
            font-weight: normal;
            min-width: 12.5em;

            @include mq($mobileBreakpoint) {
                margin: 1em 0 0;
                padding-right: 1.2em;
                padding-left: 1.2em;
            }
        }       
    }

    .book-phone-meeting {
        padding: 1em;
        box-sizing: border-box;
        background: $white;

        @include mq($mobileBreakpoint) {
            padding: 3em 2em 2em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            padding: 4em 2em 2em;
        }

        .label-input {
            margin-bottom: 1em;

            @include mq($mobileBreakpoint) {
                margin-bottom: 1.5em;
            }

            .label {
                top: 18px;
                left: 16px;
            }

            input {
                padding: 1.6em 1em .8em;
                min-height: 60px;
                max-height: 60px;
            }
        }

        .drop-down {
            min-height: 60px;
            max-height: 60px;
            margin-bottom: 1em;
            box-shadow: 0 0 1px rgba($black,0.1),0 1px 3px rgba($black,0.12);

            @include mq($mobileBreakpoint) {
                margin-bottom: 1.5em;
            }
        }

        .btn {
            @include btn($positive);
            display: block;
            margin: 1em auto 0;
            text-transform: inherit;
            color: $white;
            font-weight: normal;
            min-width: 12.5em;

            @include mq($mobileBreakpoint) {
                margin: 1.5em 0 0;
                padding-right: 1.2em;
                padding-left: 1.2em;
            }
        }
    }
}