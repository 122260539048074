.ui-block {
  position: relative;
  display: block;
  background: tint($grey-light, 80%);
  border: 1px solid $border-color;
  margin: 1em auto;
  width: 100%;
  padding: 1em 0.5em;
  box-sizing: border-box;

  @include mq($mobileBreakpoint) {
    margin: 2em auto;
    padding: 1em;
    max-width: 40em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    max-width: 60em;
  }

  &.ui-block--with-nav {
    margin: 0;
    border-top: none;
  }

  &.ui-block--with-label {
    margin-top: 1.5em;

    @include mq($mobileBreakpoint) {
      margin-top: 2em;
    }
  }

  .ui-block__label {
    position: absolute;
    top: -1.6em;
    right: 0;
    bottom: auto;
    left: 0;
    margin: auto;
    max-width: 10em;
    height: 1.6em;
    padding: 0.1em 1em;
    box-sizing: border-box;
    font-family: $font-standard;
    text-align: center;
    font-size: 0.75em;
    color: rgba($font-color,0.5);
    background: tint($grey-light, 80%);
    border: 1px solid $border-color;
    border-bottom: none;
    border-radius: $r-btn $r-btn 0 0;
    z-index: 2;

    @include mq($mobileBreakpoint) {
      top: -1.6em;
      right: auto;
      bottom: auto;
      left: -1px;
      font-size: 0.85em;
      padding: 0.2em 0.5em;
    }
  }
}