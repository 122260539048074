@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

@font-face {
    font-family: 'Switzer-Variable';
    src: url('~/assets/fonts/Switzer-Variable.woff2') format('woff2'),
        url('~/assets/fonts/Switzer-Variable.woff') format('woff'),
        url('~/assets/fonts/Switzer-Variable.ttf') format('truetype');
        font-weight: 100 900;
        font-display: swap;
        font-style: normal;
  }