.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 998;
  
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top:auto;
      right:0;
      bottom:0;
      left:0;
      margin: auto;
      z-index: 999;
      background: rgba($white,0.7);
      pointer-events: none;
    }
  
    &:after {
      content: '.';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      height: 0.6em;
      font-size: 9em;
      text-align: center;
      line-height: 0;
      z-index: 1000;
      color: transparent;
      animation: loading-dots 1500ms infinite ease both;
    }
  }