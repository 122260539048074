.order-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100vw;
    z-index: 98;
    transform: translate(-100%,0,0);
    transition: transform 150ms ease;

    .order-nav__background {
        background: rgba($black, 0);
        transition: background-color 150ms ease;
    }

    @at-root .main-app.main-app-order-nav--open .order-nav {
        transform: translate(0,0,0);
        transition: transform 150ms ease;
       
        .order-nav__background {
            width: 200vw;
            height: 100vh;
            background: rgba($black, 0.5);
            transition: background-color 150ms ease;

            @include mq($removeMobileMenuBreakPoint) {
                background: rgba($black, 0.25);
            }
        }
    }

    .order-nav__wrapper {
        background: $white;
        position: fixed;
        top: 0;
        left: 0;
        margin: auto;
        width: 100%;
        max-width: 22em;
        z-index: 99;
    }

    .order-nav__header {
        position: relative;
        padding: 1.2em 1em;
        box-sizing: border-box;
        box-shadow: $block-shadow;
        z-index: 1;

        h2 {
            margin: 0;
            text-align: center;
            font-size: 1.2em;
        }

        .order-nav__close {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            margin: auto;
            width: 3.9em;
            height: 3.9em;
            padding: 0.9em;
            box-sizing: border-box;

            svg {

                line {
                    fill:none;
                    stroke: $brand-color-light;
                    stroke-linecap:round;
                    stroke-miterlimit:10;
                    stroke-width:28px;
                }
            }
        }
    }

    .order-nav__body {
        overflow-y: auto;
        min-height: calc(100vh - 8.1em);
        max-height: calc(100vh - 8.1em);

        @include mq($removeMobileMenuBreakPoint) {
            min-height: calc(100vh - 9em);
            max-height: calc(100vh - 9em);
        }
    }

    .order-nav__footer {
        width: 100%;
        max-width: 22em;
        position: fixed;
        bottom: 0;
        left: 0;
        margin-top: auto;
        background: $white;
        padding: 0.8em 1em;
        box-sizing: border-box;
        display: flex;
        flex-flow: nowrap row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba($grey-light, 0.5);
        z-index: 1;
        box-shadow: 0 -1px 10px rgba($black, 0.1);

        @include mq($removeMobileMenuBreakPoint) {
            padding: 1.2em 1em 1em;
            position: relative;
            box-shadow: none;
        }

        .btn {
            margin: 0;
            padding: 0.8em;
            box-sizing: border-box;
            display: flex;
            flex-flow: nowrap row;
            justify-content: center;
            align-items: center;
            flex: 1;
            font-size: 0.9em;
            border: 1px solid rgba($border-color, 0.4);
            border-radius: $r-btn;
            background: none;
            cursor: pointer;
            box-shadow: $block-shadow;

            &:hover {
                box-shadow: 0 2px 4px rgba($brand-color, 0.025), 0 8px 20px rgba($brand-color, 0.05);

                span {
                    color: $blue-color;
                }

                svg path {
                    fill:  $blue-color;
                }
            }

            span {
                color: $brand-color-light;
            }

            svg {
                width: 1.2em;
                height: 1.2em;
                margin-left: 1em;

                path {
                    fill: $brand-color-light;
                }
            }

            &.btn--new-case {
                margin-right: 0.5em;
            }

            &.btn--logout {
                margin-left: 0.5em;
            }
        }
    }
}