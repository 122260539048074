.nem-footer {
  background: $white;

  .nem-footer__content {
    @extend .wrapper;
    display: flex;
    flex-flow: wrap column;
    padding-bottom: 1em;

    @include mq($removeMobileMenuBreakPoint) {
      flex-flow: wrap row;
      justify-content: space-between;
      padding: 2em 1em;
    }

    @include mq(1000) {
      padding: 2em 0;
    }
  }

  .nem-footer__link {
    flex: 1;
    align-self: center;
    margin: 0 auto;
    padding-top: 1em;
    box-sizing: border-box;
    display: block;

    @include mq($removeMobileMenuBreakPoint) {
      padding: 0;
    }

    &:first-child {
      order: 1;

      @include mq($removeMobileMenuBreakPoint) {
        order: 0;
      }
    }

    &:nth-child(2) {
      order: 0;
    }

    &:last-child {
      order: 1;

      @include mq($removeMobileMenuBreakPoint) {
        order: 0;
      }
    }

    img {
      display: block;
      width: auto;
      margin: 0 auto;

      @include mq($removeMobileMenuBreakPoint) {
        margin: 0;
      }
    }
  }
}