.lead-customer-validation {

    @include mq($mobileBreakpoint) {
        padding: 2em 1em 1em;
        box-sizing: border-box;
        width: 30em;
        margin: 0 auto;
    }

    @include mq($removeMobileMenuBreakPoint) {
        padding-top: 4em;
    }

    .drop-down {
        box-shadow: $block-shadow;
        margin-bottom: 1em;
        
        label {
            background: $white;
            min-width: 4em;
            max-width: 4em;
        }
    }

    .label-input {
        margin-bottom: 1em;
        width: 100%;

        &.label-input--postal {

            @include mq($mobileBreakpoint) {
                max-width: 8em;
                margin-right: 1em;
            }
        }

        .tag-label {
            left: 0;
            width: auto;
            max-width: 100%;
        }
    }

    .btn {
        @include btn($positive);
        display: block;
        width: 100%;
        padding: 1em;
        text-transform: inherit;
        letter-spacing: 0.02em;

        @include mq($mobileBreakpoint) {
            width: auto;
            font-size: 1em;
            padding: 0.8em 4em;
        }
    }
    
    .two-col {

        @include mq($mobileBreakpoint) {
            display: flex;
            flex-flow: nowrap row;
            justify-content: flex-start;
            align-items: center;
        }
    }
}