.msg-view {
    background: $white;
    margin: 0;
    position: relative;
    border: none !important;
    position: fixed;
    top: 3.7em;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    
    @include mq($removeMobileMenuBreakPoint) {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        margin: 0;
        z-index: auto;
    }

    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        height: 4em;
        background: linear-gradient(to top, rgba($white, 0) 0%, rgba($white, 0.35) 90%, rgba($white, 0.5) 100%);
        z-index: 1;
    }

    &:after {
        top: auto;
        bottom: 0;
        background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0.35) 90%, rgba($white, 0.5) 100%);
    }

    .box-body {
        min-width: 100%;
        height: calc(100vh - 8.2em);
        overflow-y: auto;
        border: none;
        border-top: none;
        padding: 0;

        @include mq($removeMobileMenuBreakPoint) {
            height: calc(100vh - 10.9em);
        }
    }

    .box-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .msg-item {
        padding: 1em 1em;
        box-sizing: border-box;

        &:nth-child(even) {
            border-top: 1px solid rgba($border-color, 0.5);
            border-bottom: 1px solid rgba($border-color, 0.3);
            box-shadow: 0 1px 2px rgba($grey-light, 0.5);
        }

        &:last-child {
            padding-bottom: 6em;
        }

        .msg-item__header {
            padding: 0.5em 0 1.4em;
            display: flex;
            flex-flow: nowrap row;
            justify-content: space-between;
            align-items: center;

            .msg-date {
                font-size: 0.75em;
                color: rgba($grey-dark, 0.9);

                @include mq($smallMobileBreakpoint) {
                    font-size: 0.8em;
                }

                @include mq($mobileBreakpoint) {
                    font-size: 0.85em;
                }
            }
    
            .msg-name {
                margin: 0.3em 0 0;
                font-size: 0.9em;
                font-weight: normal;
                color: rgba($font-color, 0.6);

                @include mq($smallMobileBreakpoint) {
                    font-size: 0.95em;
                }

                @include mq($mobileBreakpoint) {
                    font-size: 1em;
                }
            }
    
            .msg-topic {
                margin: 0.1em 0 0;
                font-size: 1.05em;
                font-weight: bold;
                color: $font-color;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                @include mq($smallMobileBreakpoint) {
                    font-size: 1.1em;
                }

                @include mq($mobileBreakpoint) {
                    font-size: 1.3em;
                }
            }

            .msg-item__avatar {
                position: relative;
                margin: 0.5em 1em 0 0;
                min-width: 2.5em;
                max-width: 2.5em;
                min-height: 2.5em;
                background: rgba($grey-dark, 0.4);
                border-radius: 50%;
                overflow: hidden;
    
                @include mq($smallMobileBreakpoint) {
                    min-width: 3em;
                    max-width: 3em;
                    min-height: 3em;
                }
    
                @include mq($mobileBreakpoint) {
                    min-width: 4em;
                    max-width: 4em;
                    min-height: 4em;
                }
    
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    width: 2.5em;
                    height: 2.5em;
    
                    @include mq($smallMobileBreakpoint) {
                        width: 3em;
                        height: 3em;
                    }
    
                    @include mq($mobileBreakpoint) {
                        width: 4em;
                        height: 4em;
                    }
                }
            }    
        }
    }
}