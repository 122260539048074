.message-widget {
  width: 100%;
  margin-bottom: 3em;

  @include mq($removeMobileMenuBreakPoint) {
    display: flex;
    flex-flow: nowrap row;
    margin-bottom: 4.5em;
  }

  .direct-chat {
    flex: 1;
    display: block;
    width: 100%;
    box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: auto;
    min-height: 25em;
  }

  @at-root .modal-messages .direct-chat-messages {

    @include mq($removeMobileMenuBreakPoint) {
      padding: 1.5em;
      box-sizing: border-box;
      overflow: inherit;
    }
  }

  .shared-files {

    @include mq($removeMobileMenuBreakPoint) {
      min-width: 20em;
      max-width: 20em;
    }
  }

  .message-widget__header {
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
    height: 3em;
    box-sizing: border-box;
    border-bottom: 1px solid $border-color;
    overflow: hidden;

    @include mq($mobileBreakpoint) {
      border-bottom-color: rgba($border-color, 0.5);
    }

    .message-widget__close-btn {
      display: none;
    }

    h3 {
      display: block;
      flex: 1;
      align-self: center;
      margin: 0;
      color: rgba($font-color-dark, 0.8);
      font-size: 0.9em;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($smallMobileBreakpoint) {
        font-size: 1em;
      }

      @include mq($mobileBreakpoint) {
        font-size: 1.1em;
      }
    }

    .badge {
      position: absolute;
      top: 0;
      right: 1em;
      bottom: 0;
      left: auto;
      margin: auto;
      display: block;
      text-align: center;
      font-size: 0.9em;
      font-weight: bold;
      height: 50%;
      max-width: 6.3em;
      color: rgba($font-color-dark, 0.8);

      @include mq($smallMobileBreakpoint) {
        font-size: 1em;
      }

      @include mq($mobileBreakpoint) {
        font-size: 1.1em;
      }
    }
  }

  .box {
    position: relative;
    width: 100%;
  }

  .box-body {
    padding: 10px;
  }

  .direct-chat .box-body {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    overflow-x: hidden;
    padding: 0;
  }
/*
  .direct-chat-messages {
    transform: translate3d(0, 0, 0);
    overflow: auto;
    height: calc(100vh - 206px);
    max-height: inherit;
    -webkit-overflow-scrolling: touch;

    @include mq($removeMobileMenuBreakPoint) {
      max-height: inherit;
      min-height: 292px;
      height: calc(100vh - 480px);
    }
  }
  */
  
  .direct-chat-msg, .direct-chat-text {
    display: block;

    .attachement_file a {
      text-decoration: underline;
    }
  }
  .direct-chat-msg {
    margin-bottom: 1.2em;

    @include mq($mobileBreakpoint) {
      margin-bottom: 1.4em;
    }
  }
  .direct-chat-msg:before, .direct-chat-msg:after {
    content: " ";
    display: table;
  }
  .direct-chat-msg:after {
    clear: both;
  }
  .direct-chat-messages, .direct-chat-contacts {
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -moz-transition: -moz-transform .5s ease-in-out;
    -o-transition: -o-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
  }
  .direct-chat-text {
    border-radius: 5px;
    position: relative;
    padding: 5px 10px;
    background: $grey-light;
    border: 1px solid $grey-light;
    margin: 5px 0 0 50px;
    color: $font-color-light;

    p, a {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .direct-chat-text:after, .direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: $grey-light;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }
  .direct-chat-text:after {
    border-width: 5px;
    margin-top: -5px;
  }
  .direct-chat-text:before {
    border-width: 6px;
    margin-top: -6px;
  }
  .right .direct-chat-text {
    margin-right: 50px;
    margin-left: 0;
  }
  .right .direct-chat-text:after, .right .direct-chat-text:before {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-left-color: $grey-light;
  }
  .direct-chat-img {
    border-radius: 50%;
    float: left;
    width: 40px;
    height: 40px;
  }
  .right .direct-chat-img {
    float: right;
  }
  .direct-chat-info {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
  }
  .direct-chat-name {
    font-weight: 600;
  }
  .direct-chat-timestamp {
    color: $font-color-light;
  }
  .contacts-list-img {
    border-radius: 50%;
    width: 40px;
    float: left;
  }

  .direct-chat-primary .right > .direct-chat-text {
    background: linear-gradient(to top, $blue-color 0%, tint($blue-color,5%) 100%);
    border-color: $blue-color;

    p, a {
      color: $white;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .direct-chat-primary .right > .direct-chat-text:after, .direct-chat-primary .right > .direct-chat-text:before {
    border-left-color: tint($blue-color,3%);
  }
}