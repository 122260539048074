.log-message {
    margin-top: 2em;

    &.log-message-type--WELCOME_MESSAGE, &.log-message-type--CUSTOMER_ON_HOLD, &.log-message-type--AWAITING_CUSTOMER_INFO, &.log-message-type--NO_CALL_RESPONSE_CUSTOMER {

        .log-message__body .log-message__text {
            background: $pale-green;
        }
    }

    &.log-message-type--LEAD_ARCHIVED, &.log-message-type--WELCME_ACTION_NOT_SET_ERROR {

        .log-message__body .log-message__text {
            background: $pale-red;
        }
    }

    .log-message__header {
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-end;
        align-items: center;

        .log-message__date {
            font-size: 0.9em;
            color: $font-color-light;
            text-align: right;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .log-message__date {
            font-size: 0.9em;
            text-align: right;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;;
        }
    }

    .log-message__body {
        display: flex;
        flex-flow: nowrap row;
        justify-content: space-between;
        align-items: flex-start;

        .log-message__text {
            flex: 1;
            margin-top: 0.5em;
            padding: 1em;
            box-sizing: border-box;
            background: $dda-bg;
            border-radius: $r-btn;
        }

        .log-message__avatar {
            margin: 0.5em 0 0 0.5em;
            width: 2.5em;
            height: 2.5em;
        }
    }

    .log-message__icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        position: relative;
        top: 0.4em;

        @include mq($smallMobileBreakpoint) {
            width: 1.6em;
        }
    }

    .log-message__actions {
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-start;
        align-items: center;

        a {
            display: block;
            display: flex;
            flex-flow: nowrap row;
            justify-content: space-between;
            align-items: center;
            color: $brand-color-light;
            transition: opacity 120ms ease;

            &:hover {
                opacity: 0.9;
                transition: opacity 120ms ease;
            }

            &:first-child {
                margin-right: 0.6em;
            }

            &:last-child {
                margin-left: 0.6em;
            }
        }

        svg {
            width: 1em;
            max-height: 0.9em;
            margin-left: 0.5em;

            &.log-message__phone-icon {
                margin-top: 0.2em;
            }

            &.log-message__email-icon {
                margin-top: 0.1em;
            }

            path {
                fill: $brand-color-light;
            }
        }
    }
}