.case-economy {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 4.5em;
    background: $white;
    padding: 1em;

    @include mq($removeMobileMenuBreakPoint) {
        padding: 1.5em 1em;
    }

    @include mq($largeBreakpoint) {
        padding: 2em;
    }

    h3 {
        margin: 0;
        font-size: 1.1em;

        @include mq($mobileBreakpoint) {
            margin: 0.2em 0 0.8em;
            font-size: 1.17em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            margin: 0.6em 0 1.2em;
        }
    }
    .case-economy__text {
                
        p {
            margin: 0 0 1em;
            box-sizing: border-box;
            display: block;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include mq($mobileBreakpoint) {
                padding: 0;
                margin: 0 0 1.2em;
            }
        }
    }

    .case-economy__msg {
        display: block;
        margin: 0 auto;
        background: $blue-color;
        padding: 0.8em 1em;
        color: $white;
        font-weight: bold;
        border-radius: $r-btn;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}