.mobile-hamburger {
  position:relative;
  width:3em;
  height:3em;
  cursor:pointer;

  .mobile-hamburger__bar {
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    width: 2em;
    height: 0.25em;
    background: $brand-color;
    transition:$hamburgerTransition;

    &.mobile-hamburger__bar--middle {
      transition:all $hamburgerTransition ease;
      opacity:1;
    }

    &.mobile-hamburger__bar--top {
      transition:all $hamburgerTransition ease;
      transform:translateY(-0.6em);
    }

    &.mobile-hamburger__bar--bottom {
      transition:all $hamburgerTransition ease;
      transform:translateY(0.6em);
    }
  }

  &:hover {

    .mobile-hamburger__bar.mobile-hamburger__bar--top {
      @include mq($mobileBreakpoint) {
        transform:translateY(-0.75em);
        transition:$hamburgerTransition;
      }
    }

    .mobile-hamburger__bar.mobile-hamburger__bar--bottom {
      @include mq($mobileBreakpoint) {
        transform:translateY(0.75em);
        transition:$hamburgerTransition;
      }
    }
  }

  @at-root .showMenu .mobile-menu .mobile-hamburger {

    .mobile-hamburger__bar--top {
      transform:rotate(-45deg);
      transition:$hamburgerTransition;
      background: $white;
    }

    .mobile-hamburger__bar--middle {
      transform:rotate(0);
      transition:$hamburgerTransition;
      opacity:0;
    }

    .mobile-hamburger__bar--bottom {
      transform:rotate(45deg);
      transition:$hamburgerTransition;
      background: $white;
    }
  }
}