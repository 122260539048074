.avatar {
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: auto;
  border-radius: $r-round;
  background-image: url('../../img/icons/icon-avatar.svg');
}