.react-code-input-container {
    width: 100% !important;

    .react-code-input {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        justify-content: stretch;
        align-items: center;

        input[type=number] {
            margin: 0;
            border: 1px solid $border-color !important;
            border-right: none !important;
            -moz-appearance: textfield !important;
            -webkit-appearance: none !important;
            flex: 1;
            border-radius: 0;
            max-width: 16.7%;

            &:last-child {
                border-right: 1px solid $border-color !important;
            }
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
        }
    }
}