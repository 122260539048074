.agreement-letter {
  padding-bottom: 8em;
  box-sizing: border-box;

  @include mq($mobileBreakpoint) {
    background: $white;
    box-shadow: $block-shadow;
  }

  .agreement-letter__header {
    position: relative;
    overflow: hidden;
    background: rgba($grey-light, 0.2);
    padding: 1em;
    box-sizing: border-box;
    border: 1px solid rgba($border-color, 0.4);

    @include mq($mobileBreakpoint) {
      padding: 1.5em 2em;
      display: flex;
      flex-flow: wrap row;
      justify-content: space-between;
    }

    .agreement-letter__ribbon {
      position: absolute;
      top: 1.6em;
      right: -3em;
      bottom: auto;
      left: auto;
      margin: auto;
      width: 10em;
      height: 1em;
      background: rgba($negative, 0.8);
      box-shadow: $block-shadow;
      padding: 0.6em;
      transform: rotate(45deg);

      @include mq($mobileBreakpoint) {
        top: 1em;
        right: -3.7em;
        padding: 0.5em;
      }

      &.agreement-letter__ribbon--new {
        background: rgba($positive, 0.8);
      }

      p {
        margin: 0;
        color: $white;
        display: block;
        text-align: center;
        font-size: 0.85em;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.01em;

        @include mq($mobileBreakpoint) {
          font-size: 0.7em;
        }
      }
    }

    span {
      display: block;
      margin: 0;
      font-size: 0.85em;
      line-height: 150%;
      color: shade($font-color-light, 40%);

      @include mq($mobileBreakpoint) {
        font-size: 0.9em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1em;
        line-height: 140%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .agreement-letter__client-info {

      @include mq($mobileBreakpoint) {
        width: 36%;
        min-width: 36%;
        max-width: 36%;
      }
    }
    
    .agreement-letter__logo {
      position: absolute;
      top: auto;
      right: 1em;
      bottom: 0.8em;
      left: auto;
      margin: auto;
      width: 6em;
      height: 1.5em;

      @include mq($smallMobileBreakpoint) {
        right: 1.5em;
      }

      @include mq($mobileBreakpoint) {
        position: relative;
        right: auto;
        bottom: auto;
        width: 24%;
        min-width: 24%;
        max-width: 24%;
        height: inherit;
        margin: 0 2%;
      }

      @include mq(1000) {
        width: 20%;
        min-width: 11em;
        max-width: 11em;
        margin: 0 auto;
      }

      &.agreement-letter__logo--nem {
        background: url("/assets/img/logos/nemadvokat-logo.svg") no-repeat center 50%/contain;
      }

      &.agreement-letter__logo--st {
        background: url("/assets/img/st-logo.svg") no-repeat center 50%/contain;
      }
    }

    .agreement-letter__agreement-info {

      @include mq($mobileBreakpoint) {
        width: 34%;
        min-width: 34%;
        max-width: 34%;
        align-self: flex-end;
        margin-left: 1%;
        margin-right: 1%;
      }

      span {
        @include mq($mobileBreakpoint) {
          text-align: right;
        }
      }
    }
  }

  .agreement-letter__body {

    @include mq($mobileBreakpoint) {
      padding: 2em;
      box-sizing: border-box;
    }
  }

  h2 {
    margin: 0.8em 0 0.3em;
    font-size: 1.4em;
    color: $font-color-dark;

    @include mq($mobileBreakpoint) {
      margin: 1em 0 0.45em;
      font-size: 1.5em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 1em 0 0.6em;
      font-size: 1.6em;
    }
  }

  h3 {
    margin: 1.2em 0 0.2em;
    font-size: 1.2em;
    color: $font-color-dark;

    @include mq($mobileBreakpoint) {
      margin: 1.4em 0 0.35em;
      font-size: 1.3em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 1.5em 0 0.4em;
      font-size: 1.4em;
    }
  }

  p {
    margin: 0.6em 0 1.1em;
    font-size: 0.9em;
    line-height: 150%;
    color: shade($font-color-light, 40%);

    &.intro {
      font-size: 1.05em;

      @include mq($removeMobileMenuBreakPoint) {
        margin: 1em 0 2em;
        font-size: 1.2em;
      }
    }

    &.positive {
      color: $positive;
    }
  
    &.negative {
      color: $negative;
    }

    @include mq($mobileBreakpoint) {
      margin: 0.8em 0 1.5em;
      font-size: 1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 0.8em 0 1.8em;
      font-size: 1.1em;
      line-height: 160%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  p a, a {
    color: $informative;
    text-decoration: underline;
  }

  ul {

    li {
      list-style: disc;
      color: shade($font-color-light, 40%);
      margin-bottom: 0.5em;
    }
  }

  ol {

    li {
      color: shade($font-color-light, 40%);
      margin-bottom: 0.5em;
    }
  }

  .agreement-letter__login {
    padding: 1em;
    background: rgba($grey-light, 0.75);

    .agreement-letter__login__item {
      background: $white;
      padding: 0.8em 1em;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 0.5em;
    }

    h2 {
      font-size: 1.3em;
      margin: 0.2em 0 0.8em;
    }

    p {
      margin: 0;
      font-size: 0.95em;

      span {
        font-weight: bold;
        color: $brand-color-light;
      }

      &.agreement-letter__login__small {
        font-size: 0.8em;
        font-style: italic;
        color: rgba($font-color-light,0.9);
      }
    }

    .btn {
      @include btn($brand-color-light);
      display: block;
      max-width: 10em;
      margin: 1em auto 0;


      @include mq($mobileBreakpoint) {
        margin: 1em 0 0;
      }
    }
  }

  .agreement-letter__grid {
    padding: 1em;
    background: rgba($grey-light, 0.75);

    @include mq($mobileBreakpoint) {
      display: flex;
      flex-flow: wrap row;
      justify-content: space-between;
    }

    .agreement-checkbox {
      position: relative;
      margin-left: 1.3em;
      margin-bottom: 0.5em;

      @include mq($mobileBreakpoint) {
        margin-left: 2.5%;
        width: 45%;
        min-width: 45%;
        max-width: 45%;
      }

      .agreement-checkbox__text {
        margin: 0.5em 0;

        @include mq($mobileBreakpoint) {
          font-size: 0.95em;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1em;
        }
      }

      .agreement-checkbox__check {
        position: absolute;
        pointer-events: none;
        right: auto;
        bottom: auto;
        left: -18px;
        margin: auto;
        width: 7px;
        height: 16px;
        border: 2px solid $positive;
        box-sizing: border-box;
        border-top: none;
        border-left: none;
        z-index: 0;
        transform: scale(1) rotate(45deg);
        top: 1px;

        @include mq($mobileBreakpoint) {
          left: -16px;
        }
      }
    }
  }
}