.case-menu {
    width: 100%;
    max-height: 4.5em;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: nowrap row;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    background: $brand-color;
    z-index: 2;

    a {
        position: relative;
        flex: 1;
        display: flex;
        flex-flow: nowrap column;
        justify-content: center;
        align-items: center;
        padding: 1em 0.5em;
        box-sizing: border-box;
        transition: background 110ms ease, box-shadow 110ms ease;
        max-width: 9.5em;

        @include mq($mobileBreakpoint) {
            padding: 1em;
        }

        &:hover, &.router-link-exact-active {
            background: rgba($blue-color, 0.2);
            transition: background 110ms ease, box-shadow 110ms ease;

            .case-menu__item__text {
                color: $white;
            }

            .case-menu__item__icon {
    
                path, rect, circle, polygon {
                    fill: $white;
                }
            }
        }

        &.case-menu__item--verified:after {
            content: '';
            position: absolute;
            top: -0.2em;
            right: -1.1em;
            bottom: 0;
            left: 0;
            margin: auto;
            background: url("/assets/img/icons/case-menu-check.svg") no-repeat center 50%/contain;
            width: 0.8em;
            height: 0.8em;
        }

        &.case-menu__item--tasks {
            min-width: 5.5em;

            @include mq($smallMobileBreakpoint) {
                min-width: 6em;
            }
        }

        .case-menu__item__icon {
            width: 1.5em;
            height: 1.5em;

            @include mq($smallMobileBreakpoint) {
                width: 1.6em;
                height: 1.6em;
            }

            path, rect, circle, polygon {
                fill: $white;
            }
        }
        
        .case-menu__item__text {
            font-size: 0.7em;
            text-align: center;
            color: $white;

            @include mq($smallMobileBreakpoint) {
                font-size: 0.8em;
            }
        }
    }
}