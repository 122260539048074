//styling for messages text from WYSIWYG editor
.msg-text {

    .ql-size-huge {
        display: inline-block;
        margin: 1em 0 0.2em;
        font-size: 1.4em;
        font-weight: bold;

        @include mq($mobileBreakpoint) {
            font-size: 1.5em;
        }
    }

    .ql-size-large {
        display: inline-block;
        margin: 0.9em 0 0;
        font-size: 1.1em;
        font-weight: bold;

        @include mq($mobileBreakpoint) {
            font-size: 1.2em;
        }
    }

    p {
        display: block;
        margin: 0.1em 0 0.2em;
        font-size: 0.9em;
        line-height: 150%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
            font-size: 1em;
        }
    }

    .ql-size-small {
        margin: 0.1em 0 0.2em;
        font-size: 0.8em;

        @include mq($mobileBreakpoint) {
            font-size: 0.85em;
        }
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    a {
        text-decoration: underline;
        color: $font-color-blue;
    }

    ol {
        padding-left: 1.2em;

        li {
            line-height: 130%;
            margin-bottom: 0.4em;
            list-style: inherit;
        }
    }

    ul {
        padding-left: 1.5em;

        li {
            line-height: 130%;
            margin-bottom: 0.4em;
            list-style: inherit;
        }
    }
}