.datetime-modal {
  @extend .base-modal;

  .input-fields__two {
    margin-top: 1em;
    justify-content: flex-start;
    background: $white;
    box-shadow: 0 1px 1px rgba($black,0.1),0 4px 4px rgba($black,0.05);
    box-sizing: border-box;
  }
}