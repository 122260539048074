.no-order-open {
    display: none;

    @include mq($removeMobileMenuBreakPoint) {
        margin-top: 4em;
        display: block;
        flex: 1;
        margin-left: 2em;
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-start;
        align-items: center;
    }

    img {
        margin-right: 0.8em;
        width: 1.2em;
    }

    p {
        color: #333;
        font-size: 1.5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}