.lead-header {
  position: relative;
  z-index: 1;

  &.lead-header--two-steps .lead-header__wrapper .lead-header__steps {
    max-width: 114px;

    .lead-step--2 .lead-step__dot {
      display: none;
    }

    .lead-step--3 {
      display: none;
    }
  }

  .lead-header__wrapper {
    @extend .wrapper;
    max-width: 500px;

    .lead-header__heading {
      text-align: center;
      margin-bottom: 0;
      font-size: 1.2em;

      @include mq($mobileBreakpoint) {
        font-size: 1.4em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1.6em;
      }
    }

    .lead-header__sub-heading {
      text-align: center;
      margin: 0.5em 0 0;
      font-size: 0.95em;
      color: $font-color;
      line-height: 150%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($mobileBreakpoint) {
        margin: 0.5em 0 0;
        font-size: 1.05em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        margin: 0.5em 0 0;
        font-size: 1.15em;
      }
    }

    .lead-header__loading {
      display: block;
      margin: 3em auto;
      max-width: 6em;
      animation: rotate-loader 2s ease infinite;
    }


    .lead-header__ready {
      max-width: 4em;
      margin: 1em auto 5em;
      display: block;

      @include mq($mobileBreakpoint) {
        max-width: 6em;
        margin: 2em auto 6em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        max-width: 8em;
        margin: 3em auto 7em;
      }
    }
  }
}