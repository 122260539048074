.label-radio {
  position: relative;
  margin: 1em 0 4em;

  @include mq($mobileBreakpoint) {
    margin-bottom: 3em;
  }

  label {
    width: 100%;
    margin: 0;
    padding: 1em;
    font-size:1em;
    display: block;
    cursor:pointer;
    transition: background 350ms ease;
    box-sizing: border-box;

    &:hover {
      color: $black;
    }

    .label-radio__box {
      position: absolute;
      top: 0;
      right: auto;
      bottom: 0;
      left: 0;
      margin: auto;
      border: 1px solid $black;
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }

    .label-radio__check {
      position: absolute;
      top: 0;
      right: auto;
      bottom: 0;
      left: 5px;
      margin: auto;
      background: $brand-color-light;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: transform 150ms ease, opacity 150ms ease;
    }

    .label-radio__heading {
      position: absolute;
      top: 0;
      right: auto;
      bottom: 0;
      left: 0;
      margin: auto;
      margin-left: 1.8em;
      font-size: 1.2em
    }

    .label-radio__text {
      position: absolute;
      top: 1.4em;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      margin-left: 2em;
      font-size: 1em;
      color: rgba($font-color, 0.7);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  input {
    display:none;

    &:checked ~ label {

      .label-radio__check {
        opacity: 1;
        transform: scale(1);
        transition: transform 150ms ease, opacity 150ms ease;
      }
    }
  }
}