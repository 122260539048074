.contract-table-modal {

    .modal-box .modal-box__content {
        padding: 0;

        @include mq($mobileBreakpoint) {
            padding: 1em;
        }

        .documents-table {

            td {
                padding: 0.8em 0.5em;

                @include mq($smallMobileBreakpoint) {
                    padding: 0.8em 1em;
                }
            }

            .td-flex {

                @include mq($mobileBreakpoint) {
                    display: flex;
                    flex-flow: nowrap row;
                    justify-content: flex-start;
                    align-items: center;
                }

                .td-flex__header {

                }

                .td-flex__body {
                    display: flex;
                    flex-flow: nowrap row;
                    justify-content: flex-start;
                    align-items: center;

                    @include mq($mobileBreakpoint) {
                        margin-left: auto;
                    }
                }


                .btn {
                    margin: 0.2em 0 0;
                    padding: 0.4em 1em;
                    text-transform: inherit;
                    color: $white;

                    @include mq($mobileBreakpoint) {
                        padding: 0.8em 1em;
                    }

                    &.btn--positive {
                        margin-right: 0.5em;
                    }

                    &.btn--secondary {
                        background: $brand-color-light;
                        border-color: $brand-color-light;

                        &:hover {
                            background: $brand-color-light;
                            border-color: $brand-color-light;
                        }
                    }


                }
            }

        }
    }
}