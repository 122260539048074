.back-btn {
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    margin: auto;
    box-sizing: border-box;
    border-radius: 50%;
    border: none;
    min-width: 4em;
    max-width: 4em;
    min-height: 4em;
    max-height: 4em;
    background: transparent;
    cursor: pointer;
    transition: background 110ms ease;

    @include mq($removeMobileMenuBreakPoint) {
      left: 1em;
    }

    &:hover {
      background: $brand-color-light;

      span {
        opacity: 1;
        transition: opacity 110ms ease;
      }
    }

    svg {
      position: absolute;
      top: 0;
      right: 0.3em;
      bottom: 0;
      left: 0;
      margin: auto;
      align-self: center;
      min-width: 1.5em;
      max-width: 1.5em;
      min-height: 2.5em;
      max-height: 2.5em;

      @include mq($mobileBreakpoint) {

      }

      path {
        fill: $white;
      }
    }
  }