.label-input {
  position: relative;

  &.error-shake input {
    border-color: rgba($negative,0.25);
    background: tint($negative,95%);
    transition: background 200ms ease;
  }

  .label-input__btn-remove {
    position: absolute;
    top: 0;
    right: 0.5em;
    bottom: 0;
    left: auto;
    margin: auto;
    width: 2em;
    height: 2em;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;

    &:hover img {
      filter: invert(1);
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      padding: 0;
      width: 0.9em;
      height: 0.9em;
      filter: invert(0.5);
    }
  }

  input, textarea {
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 1.6em 1em 0.8em;
    font-family: $font-standard;
    font-size: 0.9em;
    color: $font-color;
    background: $white;
    cursor: text;
    border: none;
    border-radius: $r;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba($black,0.15),0 0 1px rgba($black,0.1);
    transition: background 200ms ease, color 200ms ease;
    -webkit-appearance: none;

    &::-webkit-input-placeholder {
      color: $font-color;
      transition: color 200ms ease;
    }

    &::-moz-placeholder {
      color: $font-color;
      transition: color 200ms ease;
    }

    &:-ms-input-placeholder {
      color: $font-color;
      transition: color 200ms ease;
    }

    &:hover {
      background: shade($white,2%);
      color: $blue-color;
      transition: background 200ms ease, color 200ms ease;

      &::-webkit-input-placeholder {
        color: $blue-color;
        transition: color 200ms ease;
      }

      &::-moz-placeholder {
        color: $blue-color;
        transition: color 200ms ease;
      }

      &:-ms-input-placeholder {
        color: $blue-color;
        transition: color 200ms ease;
      }
    }

    &:focus {
      color: $blue-color;
    }

    &:focus:hover {
      color: $blue-color;
      background: tint($blue-color,96%);
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
    }

    &:focus ~ label, &.has-text ~ label {
      left: 1.3em;
      font-size: 0.75em;
      color: $font-color-light;
      -webkit-font-smoothing: antialiased;
      transform: translate3d(0,-0.6em,0);
      transition: transform 220ms ease, font-size 200ms ease;

      @include mq($mobileBreakpoint) {
        top: 1.2em;
        left: 1.3em;
        transform: translate3d(0,-0.5em,0);
      }
    }
  }

  label {
    position: absolute;
    top: 1.2em;
    left: 0.8em;
    color: $font-color;
    font-family: $font-standard;
    font-size: 1em;
    pointer-events: none;
    transition: transform 220ms ease, font-size 200ms ease;

    @include mq($mobileBreakpoint) {
      font-size: 1em;
      top: 1.15em;
      left: 1em;
    }
  }
}