.base-modal-nemblik {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 998;
  background: rgba($black, 0.6);

  &.base-modal-smaller .modal-box {
 
    @include mq($removeMobileMenuBreakPoint) {
      height: 30em;
      position: fixed;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 90%;
    }
     
    .modal-box__content {

      @include mq($removeMobileMenuBreakPoint) {
        max-height: 90%;
        padding-bottom: 0;
      }
    }
  }

  &.base-model-type-1 .modal-box {

    @include mq($removeMobileMenuBreakPoint) {
      height: 32em;
    }
  }

  &.base-model-type-4 .modal-box {

    @include mq($removeMobileMenuBreakPoint) {
      height: 35em;
    }
  }
  
  &.base-model-type-5 .modal-box {

    @include mq($removeMobileMenuBreakPoint) {
      height: 27.5em;
    }
  }

  &.base-model-type-6 .modal-box {

    @include mq($removeMobileMenuBreakPoint) {
      height: 45.7em;
    }
  }

  &.modal-mobile-only {
    @include mq($removeMobileMenuBreakPoint) {
      position: relative;
      margin: 0;
      background: none;
    }

    .modal-box {
      background: $white;

      @include mq($removeMobileMenuBreakPoint) {
        position: relative;
        box-sizing: border-box;
        display: block;
        border: none;
        background: $white;
        box-shadow: $block-shadow-large;
      }

      .modal-box__content {

        @include mq($removeMobileMenuBreakPoint) {
          padding: 1.5em;
          box-sizing: border-box;
        }
      }

      .modal-box__header__btn {

        @include mq($removeMobileMenuBreakPoint) {
          display: none;
        }
      }
    }
  }

  &.modal-messages {
    .modal-box__content {

      @include mq($removeMobileMenuBreakPoint) {
        padding: 0 !important;
        max-height: 30em;
      }
    }
  }

  &.receipt-modal {

    &.receipt-modal--personal-info .modal-box {
      @include mq($removeMobileMenuBreakPoint) {
        max-height: 26.5em;
      }
    }

    .modal-box {
      @include mq($removeMobileMenuBreakPoint) {
        max-width: 30em;
      }
    }
  }

  .modal-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 62em;
    background: $white;
    border: 1px solid $border-color;
    overflow: hidden;

    @include mq(1024) {
      max-height: 90%;
    }

    @at-root .base-modal-nemblik.base-modal-nemblik--half .modal-box {

      @include mq($removeMobileMenuBreakPoint) {
        width: 80%;
        max-height: 80%;
        max-width: 38em;
      }

      @include mq($largeBreakpoint) {
        max-height: 70%;
      }
    }
  }

  .modal-box__header {
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;

    .modal-box__header__back-btn {
      margin: auto;
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      width: 1.5em;
      margin-left: 1.4em;

      &:hover svg path {
        fill: rgba($font-color, 0.8);
      }

      svg path {
        fill: rgba($grey-dark, 0.5);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .modal-box__header__left {
      display: flex;
      flex-flow: nowrap row;
      justify-content: flex-start;
      align-self: center;
    }

    .modal-box__header__right {
      display: flex;
      flex-flow: nowrap row;
      justify-content: flex-end;
      width: 4em;
    }

    .modal-box__header__heading {
      padding: 1em;
      margin: 0;
      color: $font-color-dark;
      flex: 1;
      font-size: 0.9em;

      @include mq($mobileBreakpoint) {
        font-size: 1.1em;
        align-self: center;
        min-width: 4.5em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1.2em;
        min-width: 4.6em;
      }
    }

    .modal-box-dropdown {
      position: relative;
      align-self: center;
      z-index: 100;

      &.modal-box-dropdown--open {

        .modal-box-dropdown__btn {
          background: $white;
          box-shadow: $block-shadow;
          border-bottom: none;
          border-radius: 4px 4px 0 0;

          span {
            color: $brand-color-light;
          }

          svg line {
            stroke: $brand-color-light;
          }
        }

        .modal-box-dropdown__list {
          display: block;
        }
      }

      .modal-box-dropdown__btn {
        padding: 1em;
        box-sizing: border-box;
        display: flex;
        background: none;
        border: 1px solid transparent;
        border-bottom: none;
        cursor: pointer;

        &:hover {

          span {
            color: $brand-color-light;
          }

          svg line {
            stroke: $brand-color-light;
          }
        }

        span {
          font-size: 0.9em;
          margin: 0;
          color: $grey-dark;

          @include mq($mobileBreakpoint) {
            font-size: 1.1em;
          }
        }

        svg {
          margin-left: 0.75em;
          width: 1em;
          height: 1em;
          transform: rotate(90deg);
          align-self: center;

          line {
            fill: none;
            stroke: $grey-dark;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-width: 12px;
          }
        }
      }

      .modal-box-dropdown__list {
        display: none;
        position: absolute;
        top: 2.1em;
        right: 0;
        bottom: auto;
        left: 0;
        margin: auto;
        padding: 0;
        box-sizing: border-box;
        border: 1px solid $border-color;
        border-radius: 0 0 $r-btn $r-btn;
        background: $white;
        box-shadow: $block-shadow;

        @include mq($mobileBreakpoint) {
          top: 2.4em;
        }

        li {
          display: block;

          a {
            padding: 1em;
            box-sizing: border-box;
            font-size: 0.7em;
            display: block;
            color: $font-color;

            @include mq($mobileBreakpoint) {
              font-size: 0.8em;
            }

            &:hover {
              background: rgba($grey-light, 0.5);
            }
          }
        }
      }
    }

    .modal-box__header__btn {
      padding: 0;
      margin: 0;
      width: 4.2em;
      height: 4.2em;
      padding: 0.8em;
      box-sizing: border-box;
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        background: shade($white, 5%);
      }

      svg {
        margin-top: -1px;

        line {
          fill:none;
          stroke: $brand-color-light;
          stroke-linecap:round;
          stroke-miterlimit:10;
          stroke-width:28px;
        }
      }
    }
  }

  .modal-box__content {
    padding: 1em;
    overflow: auto;
    height: calc(100% - 79px);
    background: $white;
    -webkit-overflow-scrolling: touch;

    @include mq($mobileBreakpoint) {
      background: none;
    }

    @include mq($removeMobileMenuBreakPoint) {
      height: calc(100% - 88px);
    }
  }

  .modal-box__actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $white;
    border-top: 1px solid $border-color;
    padding: 1em 0.5em;
    box-sizing: border-box;
    display: flex;

    @include mq($mobileBreakpoint) {
      padding: 1em;
    }

    > div {
      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
      align-items: center;

      &.actions-full-width {
        width: 100%;
      }

      p {
        font-size: 0.85em;
        margin-left: 1em;
        color: $font-color-dark;

        @include mq($mobileBreakpoint) {
          font-size: 0.9em;
        }
      }
    }

    .btn {
      @include btn($brand-color-light);
      display: block;
      margin: 0 auto;
      flex: 1 0 auto;
      max-width: 12.5em;

      @include mq($smallMobileBreakpoint) {
        max-width: 13em;
      }

      @include mq($mobileBreakpoint) {
        max-width: 14.5em;
        width: 14.5em;
      }

      &.btn--positive {
        @include btn($positive);
        display: block;
        margin: 0 auto;
      }

      &.btn--full-width {
        min-width: 100%;
      }

      &.btn--positive {
        margin: 0;
      }

      &.btn--print {
        margin: 0;
        color: $font-color-light;
        background: rgba($grey-light,0.2);
        box-shadow: none;
        box-sizing: border-box;
        border: 1px solid $border-color;

          &:hover {
            background: tint($grey-light,20%);
            color: $font-color-dark;
            border: 1px solid rgba($border-color, 0.6);
          }
      }
    }
  }
}