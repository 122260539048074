.msg-new {

    .msg-item {
        padding: 1em 0;

        &:last-child {
            padding-bottom: 3em;
        }
    }

    .modal-box {
        background: $white;

        .modal-box__header {
            background: $grey-light;
        }

        .modal-box__content {
            padding: 0;

            @include mq($removeMobileMenuBreakPoint) {
                height: calc(100% - 62px);
            }
        }
    }

    .tool-line {
        margin-bottom: 0.5em;
        display: flex;
        flex-flow: nowrap row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em 0.5em 0;
        box-sizing: border-box;
        border-bottom: 1px solid $border-color;
        background: rgba($grey-light, 0.6);

        @include mq($mobileBreakpoint) {
            padding-left: 0.5em;
        }
    }

    .ql-toolbar.ql-snow {
        flex: 1;
        margin-right: 1em;
        border: none !important;
        padding: 0;

        @include mq($mobileBreakpoint) {
            padding: 8px;
        }

        .ql-formats {
            margin: 0;

            @include mq($mobileBreakpoint) {
                margin-right: 15px;
            }
        }

        .ql-header, .ql-list, .ql-color {
            display: none;

            @include mq($mobileBreakpoint) {
                display: inline-block;
            }
        }
    }

    .tool-line__actions {
        margin-left: auto;

        .btn {
            @include btn($positive);
            min-width: 5em;
            padding: 0.5em;
            font-size: 0.8em;
            font-weight: bold;
            box-sizing: border-box;
            border-radius: $r-btn;
            background: $positive;
            color: $white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include mq($smallMobileBreakpoint) {
                padding: 0.6em;
                font-size: 0.9em;
            }

            @include mq($mobileBreakpoint) {
                min-width: 6em;
            }
        }
    }

    .subject-line {
        padding: 0 1em;
        box-sizing: border-box;
        margin-bottom: 0.5em;

        .label-input {
            
            input {
                padding: 0.8em 1em;
                border: 1px solid $border-color;
                box-shadow: none;
            }

            label {
                top: 0.85em;
            }
        }
    }
    
    .text-line  {
        padding: 0.2em 1em 0;
        box-sizing: border-box;

        .msg-text {
            background: $white;
        }
        
        .ql-container.ql-snow {
            border: 1px solid $border-color;
        }

        .ql-editor {
            height: calc(100vh - 22em);

            @include mq($removeMobileMenuBreakPoint) {
                height: calc(100vh - 23.5em);
            }

            ol li, ul li {
                list-style: none;
            }
        }
    }
}