.label-checkbox {
  position: relative;

  label {
    margin: 1em 0 0.2em 0;
    font-size: 0.9em;
    font-family: $font-standard;
    color: $font-bold;
    display: block;
    position: relative;
    padding-left: 1.9em;

    span {
      display:block;
      position:absolute;
      left:0;
      transition: transform 150ms ease;
    }

    .circle {
      pointer-events: none;
      background:transparent;
      top: -16px;
      right: auto;
      bottom: auto;
      left: -16px;
      margin: auto;
      height:50px;
      width:50px;
      z-index:1;
      border-radius:50%;
    }

    .box {
      pointer-events: none;
      border:1px solid $border-color;
      background: white;
      height:16px;
      width:16px;
      z-index:1;
    }

    .check {
      position: absolute;
      pointer-events: none;
      top: 0;
      right: auto;
      bottom: auto;
      left: 6px;
      margin: auto;
      width: 6px;
      height: 14px;
      border:2px solid $positive;
      box-sizing: border-box;
      border-top: none;
      border-left: none;
      opacity:0;
      z-index:2;
      transform:rotate(180deg);
      transition: transform 1s ease, opacity 300ms ease;
    }
  }

  input[type=checkbox]   {
    display:none;

    &:checked ~ label {
      text-decoration: line-through;

      .circle {
        animation: grow-circle 450ms ease;
      }

      .box {

      }

      .check {
        opacity: 1;
        transform: scale(1) rotate(45deg);
        transition: transform 320ms ease, opacity 350ms ease;
      }
    }
  }
}

.label-checkbox-hover {

  label {
    cursor:pointer;

    &:hover {
      color: $blue-color;

      .box {
        border-color: $blue-color;
        background: tint($brand-color,96%);
      }
    }
  }
}