.validation-table {
    margin: 1em 0;
    padding: 0.5em;
    box-sizing: border-box;
    border: none;
    width: 100%;
    background: $grey-light;

    tr th {
        padding: 0.6em;
        box-sizing: border-box;
        font-family: $font-standard;
        font-weight: bold;
        text-align: left;
        color: $black;
    }

    tr td {
        padding: 0.6em;
        box-sizing: border-box;
        font-family: $font-standard;

        a {
            color: $brand-color-light;
            text-decoration: underline;

            &:hover {
                color: shade($brand-color-light, 30%);
            }
        }
    }
}