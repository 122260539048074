/* Media Query mixin */
@mixin mq($width, $type:'min') {
  @if($IE8 == false) {
    $width: calc($width / 16);
    @media all and (#{$type}-width: #{$width}em) {
      @content;
    }
  }
  @else
  {
    @if ($type == 'min' and $width <= 1200) {
      @content;
    }
  }
}

@mixin isRetina() {
  @media
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    @content;
  }
}

@mixin chrisCoyier() {
  &:after {
    display: block;
    content: '';
    width: 100%;
    clear: both;
  }
}

@mixin btn($btn-color) {
  //Layout
  margin: 0;
  border-radius: $r-btn;
  padding: 1em 0.8em;
  box-sizing: border-box;
  border: 1px solid $btn-color;
  -webkit-appearance: none;

  //Typography
  font-family: $font-bold;
  font-size: 0.7em;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  //Color
  color: tint($btn-color,90%);
  background: rgba($btn-color,0.9);
  box-shadow: none;
  transition: box-shadow 200ms ease;

  //Interaction
  cursor: pointer;
  outline: none;

  @include mq($smallMobileBreakpoint) {
    font-size: 0.8em;
  }

  @include mq($mobileBreakpoint) {
    padding: 0.8em;
    font-weight: normal;
  }

  &:hover {
    background: tint($btn-color,20%);
    color: $white;
    border: 1px solid rgba($btn-color, 0.6);
  }

  &.btn--outline {
    background: transparent;

    &:hover {
      background: rgba($btn-color, 0.1);
    }
  }

  &:disabled {
    background: tint($grey-dark,40%);
    color: shade($grey-light, 5%);
    border-color: tint($grey-dark,35%);
    cursor: not-allowed;
  }

  &.loading, &.btn--loading {
    overflow: hidden;
    position: relative;
    cursor: wait;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      min-width: 30em;
      width: 100%;
      height: 20em;
      background: rgba(shade($btn-color,50%),0.3);
      transform: translate3d(-100%,0,0);
      animation: loading-progress 6s linear both;
      z-index: 1;
    }

    &:hover {
      background: $btn-color;
    }
  }
}

@mixin icon-btn($btn-color) {
  //Layout
  margin: 0;
  border-radius: $r-btn;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid $btn-color;
  -webkit-appearance: none;
  width: 2em;
  height: 2em;

  //Typography
  font-family: $font-bold;
  font-size: 0.85em;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;

  //Color
  background: rgba($btn-color,0.9);
  box-shadow: none;
  transition: box-shadow 200ms ease;

  //Interaction
  cursor: pointer;
  outline: none;

  @include mq($removeMobileMenuBreakPoint) {
    width: 2.1em;
    height: 2.1em;
  }

  &:hover {
    background: tint($btn-color,20%);
  }

  .btn__icon {
    margin: 0 auto;
    display: block;
    width: 1em;
    height: 1em;

    svg {
      width: 100%;
      height: auto;

      path {
        fill: tint($btn-color,90%);
      }
    }
  }
}