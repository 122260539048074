.nem-header {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  min-height: 80px;
  background: $white;
  z-index: 3;
  border-top: 1px solid $border-color;
  box-shadow: $block-shadow-large;

  @include mq($mobileBreakpoint) {
    border-top: none;
    min-height: 90px;
  }

  @at-root .main-app.main-app--system-notification .nem-header {
    padding-top: 3.4em;

    @include mq(700) {
      padding-top: 2.3em;
    }
  }

  .nem-wrapper {
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    min-height: 80px;

    @include mq($mobileBreakpoint) {
      min-height: 90px;
    }

    &:after {
      width: auto;
    }
  }

  .nem-header__logo {
    max-width: 150px;
    margin-left: 0.5em;

    @include mq(350) {
      max-width: 180px;
    }

    @include mq(440) {
      max-width: 220px;
    }

    @include mq($mobileBreakpoint) {
      max-width: 270px;
      flex: 1;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-left: 0;
    }

    .nem-header__logo__new {
      display: none;
    }
  }

  .nem-header__phone {
    display: block;
    flex: 1;
    margin: 0 1em 0 auto;
    text-align: right;
    font-size: 0.8em;
    color: $black;
    font-weight: bold;

    @include mq(350) {
      font-size: 1em;
    }

    @include mq(440) {
      font-size: 1.1em;
    }

    @include mq($mobileBreakpoint) {
      font-size: 1.2em;
    }

    @include mq(1200) {
      margin-right: 0;
      font-size: 1.3em;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .mobile-menu {
    position: absolute;
    top: 2.1em;
    right: 0;
    z-index: 99;

    @include mq($removeMobileMenuBreakPoint) {
      top: 2.4em;
    }
  }
}

.lead-section {
  padding-top: 2em;

  @include mq($mobileBreakpoint) {
    padding-top: 5em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    padding-top: 6em;
  }

  &.lead-section--white {
    background: $white;
  }
}