.main-header {
  display: block;
  width: 100%;
  position: relative;
  top: 0;
  background: $brand-color;

  @include mq($removeMobileMenuBreakPoint) {
    position: fixed;
    z-index: 5;
  }

  .main-header__wrapper {
    @extend .nem-wrapper;
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;    

    .header-btn {
      position: relative;
      display: block;
      box-sizing: border-box;
      border: none;
      min-width: 4.5em;
      max-width: 4.5em;
      min-height: 4.5em;
      max-height: 4.5em;
      background: none;
      cursor: pointer;
      display: block;
      flex: 1;

      &:hover {
  
        span {
          opacity: 1;
          transition: opacity 110ms ease;
        }

        svg {

          path {
            fill: $blue-color;
          }

          line {
            stroke: $blue-color;
          }
        }
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        align-self: center;
        min-width: 2.6em;
        max-width: 2.6em;
        min-height: 2.4em;
        max-height: 2.4em;
  
        path {
          fill: $white;
        }

        line {
          fill: none;
          stroke: $white;
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 25px;
        }
      }
    }

    .header-action-btn {
      background: none;
      border: none;
      margin-left: auto;
      display: flex;
      flex-flow: nowrap row;
      justify-content: flex-end;
      align-items: center;
      min-width: 4.5em;
      min-height: 4.5em;
      max-height: 4.5em;
      cursor: pointer;
      padding: 0 0.6em;

      @include mq($smallMobileBreakpoint) {
        padding: 0 0.8em;
      }

      @include mq($mobileBreakpoint) {
        padding: 0 1em;
      }

      &:hover {
        background: rgba($blue-color, 0.2);
      }

      &.header-action-btn--reply {

        span {
          margin-right: 0.3em;
        }

        svg {
          width: 0.85em;
          height: 23px;
        }
      }

      span {
        display: block;
        font-size: 0.9em;
        font-weight: bold;
        color: $white;
        margin-right: 0.4em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($smallMobileBreakpoint) {
          font-size: 1.1em;
        }
  
        @include mq($mobileBreakpoint) {
          font-size: 1.25em;
        }
      }
      
      svg {
        width: 1.7em;
        height: 25px;
        display: block;
        margin-left: auto;

        @include mq($smallMobileBreakpoint) {
          width: 1.9em;
        }
  
        @include mq($mobileBreakpoint) {
          width: 2.2em;
        }

        path {
          fill: $white;
        }
      }
    }

    .main-header__content {
      box-sizing: border-box;
      display: flex;
      flex-flow: nowrap row;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      width: 100%;
      
      @include mq($largeBreakpoint) {
        margin-left: 1em;
      }
    }

    h1, h2 {
      display: block;
      margin: 0;
      font-size: 0.8em;
      color: $white;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($smallMobileBreakpoint) {
        font-size: 0.9em;
      }

      @include mq($mobileBreakpoint) {
        font-size: 1.1em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        font-size: 1.2em;
      }

      @at-root .main-app.main-app--hide-headers .main-header .nem-wrapper h1 {
        font-size: 0.8em;

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1.5em;
        }
      }
    }

    h2 {
      font-weight: normal;
      margin-left: 0.3em;
    }
  }
}