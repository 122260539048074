.file-upload {
  @extend .ui-block;

  p {
    margin: 0 0 1em;
    color: $font-color;
  }

  .btn {
    @include btn($brand-color-light);
  }
}