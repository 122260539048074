.deposit-modal {

    @include mq($mobileBreakpoint) {
        background: $white;
        padding: 2em 1em 1em;
        box-sizing: border-box;
        box-shadow: $block-shadow;
    }

    .deposit-modal__heading {
        display: block;
        text-align: left;
        font-size: 1em;

        @include mq($mobileBreakpoint) {
            font-size: 1.25em;
        }

        @include mq($removeMobileMenuBreakPoint) {
            font-size: 1.4em;
        }
    }

    .modal-table {
        margin: 1em 0;
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        background: rgba($black, 0.3);
        box-shadow: $block-shadow;

        @include mq($mobileBreakpoint) {
            margin-top: 2em;
        }

        .modal-table__text {
            padding: 0.4em;
            box-sizing: border-box;
            background: tint($grey-light, 40%);
            font-family: $font-standard;
            color: shade($font-color-light, 40%);

            @include mq($mobileBreakpoint) {
                padding: 0.8em;
            }
        }
    
        .modal-table__number {
            padding: 0.4em;
            box-sizing: border-box;
            background: $white;
            font-family: $font-standard;
            color: $font-color;

            @include mq($mobileBreakpoint) {
                padding: 0.8em 1.5em 0.8em 0.8em;
            }
        }
    }

    ol {
        padding: 0 0 0 1.2em;

        @include mq($mobileBreakpoint) {
            padding: 0 0 0 1.4em;
        }

         li {
            list-style: decimal;
            margin-bottom: 0.5em;
            font-size: 0.85em;
            line-height: 140%;
    
            @include mq($mobileBreakpoint) {
                font-size: 0.9em;
                line-height: 150%;
            }
         }
    }

    ul {
        padding: 0 0 0 1.2em;

        @include mq($mobileBreakpoint) {
            padding: 0 0 0 1.4em;
        }

        li {
           list-style: disc;
           margin-bottom: 0.5em;
           font-size: 0.85em;
           line-height: 140%;
   
           @include mq($mobileBreakpoint) {
               font-size: 0.9em;
               line-height: 150%;
           }
        }
   }

    p {
        text-align: left;
        font-size: 0.85em;
        line-height: 140%;

        @include mq($mobileBreakpoint) {
            font-size: 0.9em;
            line-height: 150%;
        }
    }

    ul {

        li {
            margin-bottom: 1em;
        }
    }
}