.profile-login {
  padding-top: 2em;
  padding-bottom: 5em;
  
  @include mq($mobileBreakpoint) {
      padding-bottom: 6em;
      padding-bottom: 10em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    padding-top: 8em;
    padding-bottom: 22em;
  }

  &.profile-login--part {

    .label-input {
      margin-bottom: 1.5em;
    }

    .error {
      font-weight: bold;
      line-height: 150%;
    }
  }

  .profile-login__loading {
    display: block;
    margin: 3em auto;
    max-width: 6em;
    animation: rotate-loader 2s ease infinite;
  }

  .profile-login__wrapper {
    @extend .wrapper;
    max-width: 445px;
    margin: 1em auto;
    padding: 0 1em;

    @include mq($smallMobileBreakpoint) {
      padding: 0 2em;
    }
  }

  h2 {
    font-size: 1.5em;
    color: $font-color-dark;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($smallMobileBreakpoint) {
      font-size: 1.6em;
    }

    @include mq($mobileBreakpoint) {
      font-size: 1.7em;
      margin-bottom: 1em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-bottom: 1.5em;
    }

    &.profile-login__header {
      margin-bottom: 0em;

      @include mq($mobileBreakpoint) {
        margin-bottom: 0em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        margin-bottom: 0em;
      }

      &.profile-login__header--center {
        text-align: center;
      }

      &.profile-login__header--no-margin {
        margin-top: -50px;
      }
    }
  }

  p {
    text-align: left;
    margin: 0.6em 0;
    display: block;
    font-size: 0.95em;

    @include mq($smallMobileBreakpoint) {
      font-size: 1em;
    }

    &.profile-login__text {
      margin: 1.6em 0;
      line-height: 150%;

      &.profile-login__text--center {
        text-align: center;
      }
    }
  }

  .profile-login__contact-text {
    margin: 0.8em 0;
    color: rgba($font-color-dark, 0.75);

    @include mq($mobileBreakpoint) {
      margin: 1.2em 0;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 2em 0;
    }
  }

  .profile-login__contact-link {
    color: rgba($blue-color, 0.8);

    &:hover {
      color: $blue-color;
    }
  }

  .label-input input {
    box-shadow: none;
  }

  .btn {
    @include btn($positive);
    width: 100%;
    display: block;
    border-radius: 0;
    margin-top: 1em;

    @include mq($mobileBreakpoint) {
      padding: 1.01em 1em;
      font-size: 1.1em;
      margin-top: 1.2em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin-top: 1.5em;
    }
  }

  .user-type {
    display: flex;
    flex-flow: nowrap row;
    justify-content: center;
    align-items: center;

    .user-type__item {
      box-sizing: border-box;
      padding: 1em 1.5em;
      min-height: 9.5em;
      background: rgba($white, 0.6);
      box-shadow: $block-shadow;
      display: flex;
      flex-flow: nowrap column;
      justify-content: center;
      align-items: center;

      @include mq($smallMobileBreakpoint) {
        min-height: 11em;
        padding: 1em 2em;
      }

      @include mq($mobileBreakpoint) {
        min-height: 14em;
        width: 50%;
      }

      &:hover {
        background: $white;
      }

      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child {
        margin-left: 0.5em;
      }

      img {
        display: block;
        width: 4.5em;
        height: 4.5em;
        filter: grayscale(1) brightness(0);

        @include mq($smallMobileBreakpoint) {
          width: 5.5em;
          height: 5.5em;
        }

        @include mq($mobileBreakpoint) {
          width: 6em;
          height: 6em;
        }
      }

      span {
        margin-top: 0.2em;
        display: block;
        font-family: $font-standard;
        text-align: center;
        color: $font-color-dark;
      }
    }
  }
}