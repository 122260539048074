.main-app.main-app--nem24-theme {
    background: $white;

    .nem-header {
        background: $white;
        box-shadow: none;
        border-bottom: 1px solid $border-color;

        .nem-header__logo {
            
            .nem-header__logo__old {
                display: none;
            }

            .nem-header__logo__new {
                width: 130px;
                display: block;

                @include mq($mobileBreakpoint) {
                    width: 170px;
                }

                @include mq($removeMobileMenuBreakPoint) {
                    width: 200px;
                }
            }
        }

        .nem-header__phone {
            display: flex;
            flex-flow: nowrap row;
            justify-content: center;
            align-items: center;
            padding: 0.8em;
            background: $nem24-brand-color;
            margin-left: auto;
            max-width: 8em;
            border-radius: 32px;
            font-family: $font-nem24;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: $white;

            @include mq($mobileBreakpoint) {
                padding: 0.9em;
            }

            @include mq($removeMobileMenuBreakPoint) {
                padding: 1em;
            }

            span {
                display: inline-block;
                margin-left: 0.5em;
            }
        }
    }

    .main-content {
        margin-bottom: 0;

        .lead-view .lead-view__wrapper {
            max-width: inherit;
        }

        .lead-info {
            margin: 5.1em 0 3.2em;
            overflow-x: hidden;
            overflow-y: auto;
            height: calc(100vh - 12.8em);

            @include mq($mobileBreakpoint) {
                margin-top: 5.7em;
                height: calc(100vh - 13.4em);
            }

            .messages {
                margin: 0 auto;
                max-width: 600px;
            }
    
            .messages .log-message { // Nem24 Message Styling
                display: flex;
                flex-flow: nowrap column;
                
                .log-message__header {
                    order: 2;
                    margin-top: 0.1em;
                    display: flex;
                    flex-flow: nowrap row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    margin-left: 1em;

                    @include mq($mobileBreakpoint) {
                        margin-left: 2.6em;
                    }

                    .log-message__name {
                        font-family: $font-nem24;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;

                        @include mq($mobileBreakpoint) {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    .log-message__date {
                        font-family: $font-nem24;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        position: relative;
                        order: 2;
                        margin: 0 0 0 1em;

                        @include mq($mobileBreakpoint) {
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &:before {
                            content: '・';
                            position: absolute;
                            top: 0;
                            right: auto;
                            bottom: 0;
                            left: -1em;
                            margin: auto;
                            
                        }
                    }

                    @include mq($mobileBreakpoint) {
                        margin-top: 0.25em;
                    }
                }

                .log-message__body {

                    .log-message__text {
                        order: 2;
                        background-color: $nem24-grey-light;
                        border-radius: 10px;

                        p {
                            font-family: $font-nem24;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;

                            @include mq($mobileBreakpoint){
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }
                    }

                    .log-message__avatar {
                        align-self: flex-end;
                        margin: 0.5em 0.2em 0 -1.3em;
                        position: relative;
                        top: 1.6em;
                        width: 32px;
                        height: 32px;

                        @include mq($mobileBreakpoint){
                            margin: 0.5em 0.5em 0 0;
                            top: 1.9em;
                        }
                    }
                }
            }

            .chat-actions {
                display: flex;
                flex-flow: nowrap column;
                order: 3;

                .btn.btn--chat-link {
                    margin-left: auto;
                    padding: 12px 14px;
                    box-sizing: border-box;
                    margin-top: 1em;
                    background-color: $nem24-brand-color;
                    border-radius: 6px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    color: $white;

                    &:hover {
                        background-color: tint($nem24-brand-color, 20%);
                    }

                    &.btn--clicked {
                        background-color: rgba($nem24-brand-color, 0.1);
                        color: $nem24-brand-color;
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .case-menu {
        background-color: $white;
        height: 72px;
        max-height: inherit;
        transition: all 300ms ease;

        &.input-mode {
            height: 0;

            @include mq($mobileBreakpoint) {
                height: 72px;
            }

            .case-menu__item {
                display: none;

                @include mq($mobileBreakpoint) {
                    display: flex;
                }    
            }
        }
        
        &.text-mode {
            height: 55%;
            transition: all 300ms ease;

            .case-menu__input {
                height: 100%;
                top: 0;
                z-index: 1;
                transition: top 250ms ease;

                .case-menu__text-mode__btn {
                    top: -20px;
                    width: 60px;
                    height: 60px;
    
                    &:after {
                        top: 29px;
                    }
                }

                .label-input {
                    height: 100%;

                    .input-field {
                        height: 100%;
                    }
                }

                .case-menu__input__btn {
                    padding: 1.5em;

                    span {
                        display: block;
                    }
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
            margin: auto;
            box-shadow: 0 0 70px 0 rgba($black,0.05);
            z-index: 1;
            height: 68px;
            pointer-events: none;
        }

        .case-menu__input {
            position: absolute;
            top: -51px;
            right: 0;
            bottom: auto;
            left: 0;
            margin: auto;
            width: 100%;
            height: 50px;
            border-top: 1px solid $nem24-border-color;

            .case-menu__input__btn {
                display: flex;
                flex-flow: nowrap row;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                top: auto;
                right: 0;
                bottom: 0;
                left: auto;
                margin: auto;
                background: none;
                border: none;
                padding: 1em;
                box-sizing: border-box;
                cursor: pointer;

                &:hover {

                    span {
                        color: $nem24-brand-color;
                    }

                    svg path {
                        fill: $nem24-brand-color;
                    }    
                }

                span {
                    display: none;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    margin-right: 0.5em;
                    color: $nem24-grey;
                }

                svg {
                    width: 18px;
                    height: 18px;

                    path {
                        fill: $nem24-grey;
                    }
                }
            }

            .case-menu__text-mode__btn {
                position: absolute;
                top: -7px;
                right: 0;
                bottom: auto;
                left: 0;
                margin: auto;
                width: 36px;
                height: 32px;
                background: none;
                border: none;
                box-shadow: none;
                margin: 0 auto;
                padding: 0;
                cursor: pointer;

                &:hover:after {
                    background-color: $nem24-black;
                }      

                &:after {
                    content:'';
                    position: absolute;
                    top: 16px;
                    right: 0;
                    bottom: auto;
                    left: 0;
                    margin: auto;
                    border-radius: 8px;
                    background-color: #bbb;
                    width: 36px;
                    height: 3px;
                }
            }

            .label-input {

                .input-field {
                    padding: 1em;
                    height: 50px;
                    box-shadow: none;
                    padding: 1em;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    resize: none;
                    color: $nem24-black;
                    background: $white;

                    &:focus {
                        color: $nem24-black;
                        background: $white;
                    }
                
                    &:focus:hover {
                        background: $white;
                    }
                }

                .label {
                    top: 1em;
                }
            }
        }

        .case-menu__item {
            position: relative;

            &.case-menu__item--verified:after {
                top: -0.3em;
                right: -0.6em;
                width: 1em;
                height: 1em;
            }

            .case-menu__item__icon path {
                fill: $nem24-black;
                transition: fill $hover-time-leave ease;
            }

            .case-menu__item__text {
                font-family: $font-nem24;
                color: $black;
            }

            &:hover, &.router-link-exact-active {
                background: transparent;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    background: rgba($nem24-brand-color, 0.1);
                    width: calc(100% - 2em);
                    height: 64px;
                    border-radius: 10px;
                }

                .case-menu__item__icon path {
                    fill: $nem24-brand-color;
                    transition: fill $hover-time-enter ease;
                }

                .case-menu__item__text {
                    color: $nem24-brand-color;
                }
            }
        }
    }

    .base-modal-nemblik { //Nem24 modal styling

        .book-phone-meeting {
            background: none;
            padding: 0;

            .label-input {
                margin-top: 0;
            }
        }

        .modal-box {
    
            @include mq($removeMobileMenuBreakPoint) {
                border-radius: 16px;
            }
        }

        .modal-box__header {
            border-bottom: 1px solid $nem24-border-color;

            .modal-box__header__heading {
                color: $nem24-black;
                
                @include mq($removeMobileMenuBreakPoint) {
                    margin-left: 0.5em;
                }
            }
          
            .modal-box__header__btn {

                &:hover .modal-box__header__btn__icon line {
                    stroke: $nem24-brand-color;
                }

                .modal-box__header__btn__icon {
                    margin-top: 0;

                    line {
                        stroke: $nem24-black;
                    }
                }
            }
        }

        .modal-box__content {
            background: $nem24-grey-light;

            .lead-info {
                margin-top: 0;
            }

            .date-picker {

                .date-head {
                    background-color: $nem24-grey-dark;
                }

                .date-active {
                    background-color: $nem24-brand-color;
                }
            }

            .drop-down {
                box-shadow: 0 2px 4px rgba($brand-color, 0.05);

                label {
                    min-width: 4.2em;
                    max-width: 4.2em;
                    padding: 1.4em 1em 1.2em;
                    font-family: $font-nem24;
                    background-color: $white;
                }

                .drop-down__select {
                    color: $dda-text;
                    box-shadow: none;

                    &:hover {
                        background: $white;
                        color: $dda-text;
                    }
                }
            }

            .label-input {              

                label {
                    font-family: $font-nem24;
                }

                input {
                    font-family: $font-nem24;
                    box-shadow: 0 2px 4px rgba($brand-color, 0.05);
                }

                input:focus, .label-input textarea:focus {
                    color: $nem24-black;
                    background: $white;
                }
            }

            .btn.btn--positive {
                background: $nem24-brand-color;
                border-color: $nem24-brand-color;
            }

            .confirm-order-block {
                background: none;
                box-shadow: none;
                border: none;

                .confirm-order-block__content p {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            
        }

        .modal-box__actions {
            border-top: 1px solid $nem24-border-color;
            
            .btn {
                font-family: $font-nem24;
                background-color: $nem24-brand-color;
                border: 1px solid $nem24-brand-color;
                text-transform: inherit;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }
}