.language-selector {
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5em 0 0;
    max-width: 20em;

    .language-selector__btn {
        display: block;
        width: 2.3em;
        min-width: 2.3em;
        max-width: 2.3em;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        color: $brand-color;

        &:hover {
            color: $brand-color-light;
        }

        &:first-child:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 1px;
            height: 1em;
            background: $black;
        }

        &.language-selector__btn--active {
            font-weight: bold;
        }
    }
}